import { useState } from "react";
import Button from "app/storybookComponents/Button";
import { OverlayTrigger, Popover } from "react-bootstrap";

// this component will have the state of the show/hide of the popover within the component itself
interface SimplePopoverProps {
  popoverTitle?: string;
  popoverContent: JSX.Element;
  children: JSX.Element;
  trigger?: "click" | "hover";
  popoverStyle?: React.CSSProperties;
}

export default function SimplePopover({
  popoverTitle,
  popoverContent,
  children,
  trigger = "click",
  popoverStyle,
}: Readonly<SimplePopoverProps>) {
  const [show, setShow] = useState(false);

  const overlay = (
    <Popover
      className="team-leader-popover"
      style={popoverStyle}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => {
        if (trigger === "hover") {
          setShow(false);
        }
      }}
    >
      <div className="column-gap-16px">
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <b>{popoverTitle}</b>
          </p>
          <Button
            xIcon
            variant="secondary-gray"
            className="border-0"
            size="sm"
            onClick={() => setShow(false)}
            style={{ padding: "2px 4px", borderRadius: "2px", zIndex: 999 }}
          />
        </div>
        {popoverContent}
      </div>
    </Popover>
  );
  return (
    <OverlayTrigger
      rootClose
      overlay={overlay}
      trigger={trigger === "hover" ? ["hover", "focus"] : "click"}
      placement="bottom"
      show={show}
      onToggle={setShow}
    >
      <div style={{ cursor: "pointer", width: "fit-content" }}>{children}</div>
    </OverlayTrigger>
  );
}
