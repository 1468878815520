import communicationIcon from "resources/images/icon-communicationstyle.svg";
import workStyleIcon from "resources/images/icon-workstyle.svg";
import thinkingStyleIcon from "resources/images/icon-decisionmakingstyle.svg";
import communicateIcon from "resources/manager_guide_icons/manager-guide-icon-communicate.svg";
import supportIcon from "resources/manager_guide_icons/manager-guide-icon-communicate.svg";
import motivateIcon from "resources/manager_guide_icons/manager-guide-icon-communicate.svg";
import achievementIcon from "resources/trait_icons/trait-icons-achievement.svg";
import assertivenessIcon from "resources/trait_icons/trait-icons-assertiveness.svg";
import competitivenessIcon from "resources/trait_icons/trait-icons-competitiveness.svg";
import conscientiousnessIcon from "resources/trait_icons/trait-icons-conscientiousness.svg";
import cooperativenessIcon from "resources/trait_icons/trait-icons-cooperativeness.svg";
import extroversionIcon from "resources/trait_icons/trait-icons-extroversion.svg";
import motivationIcon from "resources/trait_icons/trait-icons-motivation.svg";
import opennessIcon from "resources/trait_icons/trait-icons-openness.svg";
import patienceIcon from "resources/trait_icons/trait-icons-patience.svg";
import selfConfidenceIcon from "resources/trait_icons/trait-icons-self-confidence.svg";

export const ManagerGuideNavTitles = {
  Communication: {
    title: "How to Communicate",
    key: "Communication",
    icon: communicateIcon,
  },
  Support: {
    title: "How to Support",
    key: "Support",
    icon: supportIcon,
  },
  Motivation: {
    title: "How to Motivate",
    key: "Motivation",
    icon: motivateIcon,
  },
} as { [key: string]: { title: string; key: string; icon: string } };

export const CollaborationGuideTitles = [
  {
    title: "Communication Style",
    key: "Communication",
    icon: communicationIcon,
  },
  {
    title: "Work Style",
    key: "WorkStyle",
    icon: workStyleIcon,
  },
  {
    title: "Thinking Style",
    key: "Decision-Making",
    icon: thinkingStyleIcon,
  },
] as const;

export const LIKERT_SCALE_VALUES = [1, 2, 3, 4, 5] as const;

export const WORK_PLACE_INSIGHTS_REPORT_SECTION_TITLES = {
  "notable-traits": "Notable Traits",
  "work-communication-interaction-style":
    "Work, Communication & Interaction Style",
  "temperament-attitudes-outlook": "Temperament, Attitudes & Outlook",
  "strengths-potential-challenges": "Strengths & Potential Challenges",
  "development-suggestions": "Development Suggestions",
  "workplace-challenges-motivators": "Workplace Challenges & Motivators",
} as const;

// This constant is used to map the trait name to the icon and class name which corresponds to color
export const WORKPLACE_INSIGHT_TRAIT_INFO = {
  Achievement: {
    icon: achievementIcon,
    className: "sapphire",
  },
  Motivation: {
    icon: motivationIcon,
    className: "sapphire",
  },
  Conscientiousness: {
    icon: conscientiousnessIcon,
    className: "sapphire",
  },
  Assertiveness: {
    icon: assertivenessIcon,
    className: "gold",
  },
  Extroversion: {
    icon: extroversionIcon,
    className: "gold",
  },
  Cooperativeness: {
    icon: cooperativenessIcon,
    className: "gold",
  },
  Competitiveness: {
    icon: competitivenessIcon,
    className: "poppy",
  },
  Patience: {
    icon: patienceIcon,
    className: "poppy",
  },
  "Self-Confidence": {
    icon: selfConfidenceIcon,
    className: "cyan",
  },
  Openness: {
    icon: opennessIcon,
    className: "cyan",
  },
} as {
  [key: string]: {
    icon: string;
    className: string;
  };
};

export const WORKPLACEINSIGHTS_COACHBOT_PROMPT: {
  [key: string]: {
    coachBotIntro: string;
    preSelectedPrompts: string[];
    chatType: string;
  };
} = {
  workplaceInsightsSelf: {
    coachBotIntro: "Want to chat about this workplace insight report?",
    preSelectedPrompts: [
      "Help me understand my personality",
      "Help me understand how my personality can be a strength at work",
      "Let's explore how my personality may be limiting me at work right now",
    ],
    chatType: "workplaceInsights",
  },
  managerGuide: {
    coachBotIntro: "What do you want to chat about?",
    preSelectedPrompts: [],
    chatType: "managerGuide",
  },
  collaborationGuide: {
    coachBotIntro: "How can I help you better manage this person?",
    preSelectedPrompts: [
      "Help me collaborate better with this person",
      "Help me communicate better with this person",
      "Help me resolve a conflict with this person",
    ],
    chatType: "collaborationGuide",
  },
  teamPersonalityGuide: {
    coachBotIntro: "Want to chat about this team personality report?",
    preSelectedPrompts: [
      "Help me understand it",
      "Help me reflect on how personality helps our team",
      "Help me reflect on how personality limits our team",
    ],
    chatType: "teamPersonalityGuide",
  },
};
