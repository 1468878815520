import UploadCsvButton from "app/storybookComponents/Button/UploadCsvButton";
import Button from "app/storybookComponents/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SearchableInput from "app/storybookComponents/SearchableInput";
import { Card } from "react-bootstrap";
import EditableData from "./EditableData";
import {
  RawRowData,
  StoredRows,
  EditableDataHeaders,
  RowData,
  NewPreviewCSVResponse,
  ErrorByType,
} from "./types";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectPreviewCsvUploadStatus,
  clearPreviewInviteUsersViaCSVResponse,
  setUploadedData as setUploadedDataAction,
  addUploadedData,
  previewInviteUsersViaCSV,
  selectErrors,
  removeError,
  getErrorsByType,
  selectErrorsByType,
  selectCheckedEmails,
  selectUploadedData,
  selectEmailCheckStatusByRowId,
  selectRevalidateBeforeMovingToNextStep,
  selectCheckedManagerEmails,
  selectActiveCSVHeaders,
  uploadUsersViaCSV,
} from "./slice";
import Loading from "app/storybookComponents/Loading";
import { PEOPLE_COLUMNS, PeopleInvalidationErrorKey } from "./constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PeopleConfirmationScreen from "./PeopleConfirmationScreen";
import NavigateBackButton from "app/components/NavigateBackButton";
import ErrorBanner from "./ErrorBanner";
import SuccessBanner from "./SuccessBanner";
import {
  selectAllCompanyUsersById,
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "../Global/slice";
import { useNavigate } from "react-router-dom";
import AddSingleUserModal from "./People/AddSingleUserModal";
import EmptyCard from "app/storybookComponents/Cards/EmptyCard";
import { CSVUploadTableStructureError } from "app/storybookComponents/Button/types";
import IncorrectHeaders from "./IncorrectHeaders";
import {
  isDangerFnForEmail,
  isDangerFunction,
  isManagerEmailDanger,
  onEmailUnFocus,
  onGenderFieldUnFocus,
  onManagerEmailUnFocus,
  onRequiredFieldUnFocus,
} from "./helpers";

interface PeopleCsvUploadProps {
  teamId?: number | null;
}

const itemsPerPage = 20;

const PeopleCsvUpload: React.FC<PeopleCsvUploadProps> = ({ teamId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // --------------------------- App Selectors ---------------------------
  const previewCsvUploadStatus = useAppSelector(selectPreviewCsvUploadStatus);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const uploadedData = useAppSelector(selectUploadedData);
  const csvUploadErrors = useAppSelector(selectErrors); // This allows us to validate the fields as the user types
  const errorsByType = useAppSelector(selectErrorsByType); // this allows us to group the errors by type
  const checkedEmails = useAppSelector(selectCheckedEmails);
  const emailCheckStatusByRowId = useAppSelector(selectEmailCheckStatusByRowId);
  const revalidateBeforeMovingOn = useAppSelector(
    selectRevalidateBeforeMovingToNextStep
  );
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const checkedManagerEmails = useAppSelector(selectCheckedManagerEmails);
  const activeHeaders = useAppSelector(selectActiveCSVHeaders);

  // --------------------------- States ---------------------------
  const [error, setError] = useState<"file-size-error" | "empty-file" | null>(
    null
  );
  const [structureError, setStructureError] =
    useState<CSVUploadTableStructureError | null>(null);
  const [inputText, setInputText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openedErrorKey, setOpenedErrorKey] =
    useState<PeopleInvalidationErrorKey | null>(null);
  const [showReviewScreen, setShowReviewScreen] = useState(false);
  const [reviewSettings, setReviewSettings] = useState({
    isInviteAllMembersToggleOn: false,
  });
  const [isAddRowModalOpened, setIsAddRowModalOpened] = useState(false);
  const [errorByTypeCopy, setErrorByTypeCopy] = useState<ErrorByType>({});

  // --------------------------- Use Effect ---------------------------
  useEffect(() => {
    // when unmounting, clear the preview response
    return () => {
      dispatch(clearPreviewInviteUsersViaCSVResponse());
    };
  }, [dispatch]);

  // --------------------------- Action Functions ---------------------------

  // --------------------------- Row Data ---------------------------
  const allFilteredRowIds = useMemo(() => {
    let rowIds = Object.keys(uploadedData).map(Number);
    // if no input text then return all the rowIds
    if (!inputText) {
      return rowIds;
    }

    // if there is input text then we need to filter the rowIds
    return rowIds.filter((id) => {
      const row = uploadedData[id];
      if (!row) {
        return false;
      }

      return Object.values(row).some((val) =>
        String(val).toLowerCase().includes(inputText.toLowerCase())
      );
    });
  }, [uploadedData, inputText]);

  const visibleRows = useMemo(() => {
    const newRows: StoredRows = {};

    allFilteredRowIds.forEach((rowId) => {
      const row = uploadedData[rowId];
      if (row) {
        newRows[rowId] = row;
      }
    });

    return newRows;
  }, [uploadedData, allFilteredRowIds]);

  // --------------------------- Helpers ---------------------------

  const setUploadedData = (data: StoredRows) => {
    dispatch(setUploadedDataAction(data));
  };

  const getLastPage = () =>
    Math.ceil(allFilteredRowIds.length / itemsPerPage) || 1;

  const getTableHeaders = useCallback(() => {
    const PEOPLE_COLUMNS_MAP_TEMPLATE: EditableDataHeaders = {
      firstName: {
        displayName: "First Name",
        isDangerFunction: isDangerFunction("firstName", csvUploadErrors),
        onCellUnFocus: onRequiredFieldUnFocus(
          dispatch,
          csvUploadErrors,
          "firstName"
        ),
      },
      lastName: {
        displayName: "Last Name",
        isDangerFunction: isDangerFunction("lastName", csvUploadErrors),
        onCellUnFocus: onRequiredFieldUnFocus(
          dispatch,
          csvUploadErrors,
          "lastName"
        ),
      },
      jobTitle: {
        displayName: "Job Title",
      },
      gender: {
        displayName: "Gender",
        isDangerFunction: isDangerFunction("gender", csvUploadErrors),
        onCellUnFocus: onGenderFieldUnFocus(dispatch),
        dropDownOptions: ["Male", "Female", "Non-binary", "Not disclosed"],
      },
      emailAddress: {
        displayName: "Email",
        isDangerFunction: isDangerFnForEmail(csvUploadErrors, checkedEmails),
        onCellUnFocus: onEmailUnFocus(
          dispatch,
          checkedEmails,
          csvUploadErrors,
          uploadedData
        ),
        isLoadingFunction: (rowData) =>
          emailCheckStatusByRowId?.[rowData.id] === "loading",
      },
      managerEmailAddress: {
        displayName: "Manager Email",
        isDangerFunction: isManagerEmailDanger(
          checkedManagerEmails,
          csvUploadErrors
        ),
        onCellUnFocus: onManagerEmailUnFocus(
          dispatch,
          checkedManagerEmails,
          uploadedData
        ),
      },
    };

    const PEOPLE_COLUMNS_MAP: EditableDataHeaders = {};
    activeHeaders.forEach((header) => {
      if (PEOPLE_COLUMNS_MAP_TEMPLATE[header]) {
        PEOPLE_COLUMNS_MAP[header] = PEOPLE_COLUMNS_MAP_TEMPLATE[header];
      }
    });

    return PEOPLE_COLUMNS_MAP;
  }, [
    csvUploadErrors,
    dispatch,
    emailCheckStatusByRowId,
    uploadedData,
    checkedEmails,
    checkedManagerEmails,
    activeHeaders,
  ]);

  // --------------------------- Action Functions ---------------------------
  const handleCSVUploadSuccess = async (payload: RawRowData[]) => {
    setStructureError(null);
    setOpenedErrorKey(null);
    setShowReviewScreen(false);
    const newUpdatedData: StoredRows = {};

    payload.forEach((row, index) => {
      const gender =
        typeof row.gender === "string" ? row.gender?.toLowerCase() : row.gender;
      newUpdatedData[index] = {
        firstName: row.firstName,
        lastName: row.lastName,
        emailAddress: row.emailAddress,
        jobTitle: row.jobTitle,
        gender,
        managerEmailAddress: row.managerEmailAddress,
        id: Number(index),
      };
    });

    // We need to make the request to the backend so we can validate the emails
    setUploadedData(newUpdatedData);
    await dispatch(previewInviteUsersViaCSV({ rows: newUpdatedData }));
    dispatch(getErrorsByType());
  };

  const onContinueClick = async () => {
    // If we have this flag set then before moving on to the next step we will need to revalidate the rows.
    if (revalidateBeforeMovingOn) {
      const resp = (await dispatch(previewInviteUsersViaCSV({}))) as {
        payload?: {
          response: NewPreviewCSVResponse;
        };
      };

      if (
        !resp.payload ||
        (resp.payload.response?.errors &&
          Object.keys(resp.payload.response.errors).length)
      ) {
        return dispatch(getErrorsByType());
      }
    }
    setShowReviewScreen(true);
  };

  const onDeleteRow = async (rowId: number) => {
    const newRows = { ...uploadedData };
    delete newRows[rowId];
    setUploadedData(newRows);
    await dispatch(removeError({ rowId }));
    dispatch(getErrorsByType());
  };

  const onAddUserRow = (rowInfo: RowData) => {
    dispatch(
      addUploadedData({
        [rowInfo.id]: rowInfo,
      })
    );
    setCurrentPage(getLastPage());
  };

  // --------------------------- Getter Functions ---------------------------

  const getPageContent = () => {
    if (previewCsvUploadStatus === "loading") {
      return <Loading />;
    }

    if (showReviewScreen) {
      return (
        <PeopleConfirmationScreen
          onReviewPeople={() => setShowReviewScreen(false)}
          isInviteAllMembersToggleOn={reviewSettings.isInviteAllMembersToggleOn}
          setInviteAllMembersToggle={(value) =>
            setReviewSettings({
              ...reviewSettings,
              isInviteAllMembersToggleOn: value,
            })
          }
          onConfirm={async () => {
            await dispatch(
              uploadUsersViaCSV({
                onSuccess: () => navigate("/AdminConsole/People"),
                sendInvitations: reviewSettings.isInviteAllMembersToggleOn,
              })
            );
          }}
        />
      );
    }

    if (openedErrorKey) {
      return getErrorScreen();
    }

    if (allFilteredRowIds.length === 0 && inputText) {
      return (
        <EmptyCard
          title={`No results found for "${inputText}"`}
          bodyText="Please try another search term."
        />
      );
    }

    if (allFilteredRowIds.length === 0) {
      return (
        <EmptyCard
          title="No people to review"
          bodyText="Please upload a .csv file to add people to your organization."
          buttons={
            <>
              <Button
                onClick={() => {
                  setIsAddRowModalOpened(true);
                }}
              >
                <FontAwesomeIcon icon="plus" className="me-2" />
                Add user
              </Button>
              {getCSVUploadButton("Upload CSV file")}
            </>
          }
        />
      );
    }

    const errorElements = Object.entries(errorsByType).map(([errorKey, val]) =>
      getErrorBanner(errorKey, val.length)
    );

    return (
      <EditableData
        rows={visibleRows}
        headers={getTableHeaders()}
        setRows={setUploadedData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalRows={allFilteredRowIds.length}
        errorElement={errorElements}
        addPagination
        continueButton={getContinueButton()}
        onDeleteRow={onDeleteRow}
      />
    );
  };

  const getContinueButton = () => {
    const isDisabled = Object.keys(errorsByType).length > 0;
    return (
      <div className="row-gap-6px">
        <Button
          onClick={() => {
            setIsAddRowModalOpened(true);
          }}
          variant="secondary-blue"
        >
          <FontAwesomeIcon icon="plus" className="me-2" />
          Add user
        </Button>
        <Button
          onClick={() => {
            if (isDisabled) return;
            onContinueClick();
          }}
          disabled={isDisabled}
        >
          Continue
        </Button>
      </div>
    );
  };

  // This screen will show the errors with the ability to view them by error type
  const getErrorScreen = () => {
    const errorArr = Object.entries(errorByTypeCopy);
    if (!errorArr.length) {
      return getErrorBannerComponent(
        "",
        "",
        () => {},
        true,
        "All errors have been resolved.",
        true
      );
    }

    return (
      <>
        {errorArr.map(([errorKey, val], idx) => {
          const trueLength = errorsByType[errorKey]?.length || 0;

          if (openedErrorKey !== errorKey || !trueLength) {
            return (
              <React.Fragment key={errorKey}>
                {getErrorBanner(errorKey, trueLength)}
              </React.Fragment>
            );
          }

          const errorRows: StoredRows = {};
          val.forEach((id) => {
            if (!uploadedData[id]) return;
            errorRows[id] = uploadedData[id];
          });

          return (
            <React.Fragment key={errorKey}>
              {getErrorBanner(errorKey, trueLength)}
              <EditableData
                headers={getTableHeaders()}
                setRows={(rows) =>
                  // either we need to account for the row removal here or add a new Prop that will allow us to remove the row
                  setUploadedData({ ...uploadedData, ...rows })
                }
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalRows={val.length}
                addPagination
                rows={errorRows}
                onDeleteRow={onDeleteRow}
              />
              {idx !== errorArr.length - 1 && <hr />}
            </React.Fragment>
          );
        })}
        <Button
          onClick={() => {
            setOpenedErrorKey(null);
          }}
          variant="secondary-blue"
        >
          Back to original view
        </Button>
        <Button
          onClick={() => {
            onContinueClick();
            setOpenedErrorKey(null);
          }}
        >
          Revalidate
        </Button>
      </>
    );
  };

  const getErrorBanner = (errorKey: string, rowCount: number) => {
    if (!errorKey) {
      return null;
    }
    const isOrAre = rowCount === 1 ? "is" : "are";
    const plural = rowCount === 1 ? "" : "s";
    const onClick = () => {
      if (openedErrorKey === null) {
        setErrorByTypeCopy(errorsByType);
      }
      setOpenedErrorKey(errorKey);
      setInputText("");
      currentPage !== 1 && setCurrentPage(1);
    };

    const isBannerOpened = openedErrorKey === errorKey;

    const isErrorCleared = !rowCount;
    switch (errorKey) {
      case "invalidEmail":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} invalid email${plural}. Emails must be valid and align with your companies domain settings.`,
          "View invalid emails",
          onClick,
          isBannerOpened,
          "All emails fields have been validated.",
          isErrorCleared
        );
      case "invalidGender":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} invalid gender field${plural}. Gender must match one of the following options: Male, Female, Non-binary, and Not Disclosed.`,
          "View invalid gender fields",
          onClick,
          isBannerOpened,
          "All gender fields have been completed.",
          isErrorCleared
        );
      case "emailAlreadyExistsInAnotherOrganization":
      case "userExistsInAnotherOrganization":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} email${plural} that ${isOrAre} already associated with another organization. Please fix ${
            rowCount === 1 ? "that email" : "those emails"
          } before continuing.`,
          "View existing emails",
          onClick,
          isBannerOpened,
          "All emails are associated with new users.",
          isErrorCleared
        );
      case "duplicateEmail":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} duplicate email${plural}. All emails must be unique.`,
          "View duplicate emails",
          onClick,
          isBannerOpened,
          "All emails fields have been validated.",
          isErrorCleared
        );
      case "invalidEmailDomain":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} email${plural} with an invalid domain. Please fix the email domains before continuing.`,
          "View invalid email domains",
          onClick,
          isBannerOpened,
          "All email domains have been validated.",
          isErrorCleared
        );
      case "emailAlreadyExists":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} email${plural} that already exists in this organization. Please fix the email before continuing.`,
          "View existing emails",
          onClick,
          isBannerOpened,
          "All emails are associated with new users.",
          isErrorCleared
        );
      case "managerEmailNotFound":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} manager email${plural} not found. Please fix the manager emails before continuing.`,
          "View missing manager emails",
          onClick,
          isBannerOpened,
          "All manager emails have been validated.",
          isErrorCleared
        );
      case "managerEmailDeactivated":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} manager${plural} that will be deactivated. Please update or remove deactivated managers from these rows.`,
          "View deactivated manager emails",
          onClick,
          isBannerOpened,
          "All manager emails have been validated.",
          isErrorCleared
        );
      case "emailSameAsManager":
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} email${plural} that is the same as the manager email. Please fix the errors before continuing.`,
          "View emails same as manager",
          onClick,
          isBannerOpened,
          "All emails are associated with new users.",
          isErrorCleared
        );
      default:
        return getErrorBannerComponent(
          `There ${isOrAre} ${rowCount} row${plural} with missing fields. Please fix the missing fields before continuing.`,
          "View empty fields",
          onClick,
          isBannerOpened,
          "All required fields have been completed.",
          isErrorCleared
        );
    }
  };

  const getErrorBannerComponent = (
    label: string,
    buttonLabel: string,
    onClick: () => void,
    isAccordionOpen?: boolean,
    successMessage?: string,
    isSuccessful?: boolean
  ) => {
    if (isSuccessful) {
      return (
        <SuccessBanner
          label={successMessage ?? ""}
          onActionClick={() => {
            setOpenedErrorKey(null);
          }}
          buttonLabel="View all people"
        />
      );
    }

    return (
      <ErrorBanner
        buttonLabel={buttonLabel}
        label={label}
        hideButton={!!isAccordionOpen}
        onClick={onClick}
      />
    );
  };

  const getUploadError = () => {
    if (!error && !structureError) {
      return null;
    }

    if (error === "empty-file") {
      return (
        <div className="warning-banner red">
          <p>
            Sorry, the file you uploaded is empty. Please double check your file
            and try again.
          </p>
        </div>
      );
    }

    if (error === "file-size-error") {
      return (
        <div className="warning-banner red">
          <p>
            <FontAwesomeIcon icon="exclamation-triangle" className="me-2" />
            You can not process more than 5,000 users at one time. Please
            re-upload your file and try again.
          </p>
        </div>
      );
    }

    return (
      <>
        <hr className="m-0" />
        <div>
          <IncorrectHeaders {...structureError} />
        </div>
        <hr className="m-0" />
      </>
    );
  };

  const getUploadDataHeader = () => {
    if (showReviewScreen) {
      return null;
    }
    const organizationOrTeam = teamId ? "team" : "organization";
    return (
      <>
        <h2>
          {Object.keys(uploadedData).length
            ? "Review CSV Upload"
            : "Upload CSV"}
          {teamId && teamByTeamId[teamId]
            ? ` for "${teamByTeamId[teamId].teamName}"`
            : ""}
        </h2>
        <div>
          <p style={{ marginTop: "-12px" }}>
            Review, add, or edit the people joining your {organizationOrTeam}{" "}
            below. If any member is missing, you can re-upload the .csv file by
            clicking on “re-upload .csv”. There will be an option to send an
            invitation email to all people in your {organizationOrTeam} after
            you have reviewed people.
          </p>
        </div>
        {getUploadError()}
        {openedErrorKey ? null : (
          <SearchableInput
            placeholder="Search by name, job title, or email..."
            inputText={inputText}
            setInputText={(e) => {
              setInputText(e);
              setCurrentPage(1);
            }}
            noDropdownIndicator
          />
        )}
      </>
    );
  };

  const getCSVUploadButton = (buttonText: string = "Re-upload .csv") => {
    return (
      <UploadCsvButton
        resetUpload={() => {
          setUploadedData({});
          setOpenedErrorKey(null);
        }}
        onCSVUploadSuccess={handleCSVUploadSuccess}
        templateColumns={PEOPLE_COLUMNS}
        setStructureError={setStructureError}
        setError={setError}
        maxRows={5000}
        buttonText={
          <>
            <FontAwesomeIcon icon="upload" className="me-2" />
            {buttonText}
          </>
        }
      />
    );
  };

  const getNewRowId = () => {
    let maxId = 0;
    Object.keys(uploadedData).forEach((id) => {
      maxId = Math.max(maxId, Number(id));
    });
    return maxId + 1;
  };

  const getEmailOfManager = (managerUID?: number) => {
    if (!managerUID) return "";
    return usersById[managerUID]?.emailAddress || "";
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <AddSingleUserModal
        isOpen={isAddRowModalOpened}
        onClose={() => setIsAddRowModalOpened(false)}
        onSaveUserDataRow={(data) => {
          const id = getNewRowId();
          const managerEmailAddress = getEmailOfManager(
            data.managerUserAccountId
          );
          onAddUserRow({ ...data, id, managerEmailAddress });
          setIsAddRowModalOpened(false);
          setStructureError(null);
        }}
        showAccessRoleSection={false}
      />
      <div className="header-buttons">
        {showReviewScreen ? (
          <Button
            onClick={() => setShowReviewScreen(false)}
            variant="secondary-blue"
            style={{ border: "none" }}
          >
            <FontAwesomeIcon icon="arrow-left" className="me-2" /> Back
          </Button>
        ) : (
          <NavigateBackButton />
        )}
        {allFilteredRowIds.length ? (
          <div className="header-buttons-group">{getCSVUploadButton()}</div>
        ) : null}
      </div>
      <Card
        style={{
          padding: "20px",
          gap: "20px",
        }}
      >
        {getUploadDataHeader()}
        {getPageContent()}
      </Card>
    </>
  );
};

export default PeopleCsvUpload;
