import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { toast } from "react-toastify";
import {
  CheckedEmails,
  ErrorByType,
  ManagerAndDirectReports,
  NewPreviewCSVResponse,
  NewPreviewCSVResponseError,
  PreviewCSVUploadOptions,
  RowData,
  StoredRows,
  TimeToCompleteCSVUploadResponse,
} from "./types";
import { TestTakerMatch } from "../AdminConsole/types";
import { getAllUsers, updateUsersAction } from "../Global/slice";
import { successNotificationOptions } from "utils/constants";
import { linkTestTaker, setInviteModalOpen } from "../AdminConsole/slice";
import { UserInfo } from "../Global/types";

// ------------------ State Type/Structure ------------------
export interface ExampleState {
  revalidateBeforeMovingToNextStep: boolean;
  emailCheckStatusByRowId: {
    [rowId: number]: responseStatus;
  };
  uploadedData: StoredRows;
  errors: NewPreviewCSVResponseError;
  errorsByType: ErrorByType;
  checkedEmails: CheckedEmails;
  checkedManagerEmails: CheckedEmails;
  cvsUploadOptions: PreviewCSVUploadOptions;
  activeCSVHeaders: string[];
  previewDataResponse: Omit<NewPreviewCSVResponse, "errors">; // should contain the addedEmployeeIds, updateExistingEmployees, deactivateEmployeesNotInCSV
  previewInviteUsersViaCSVStatus: responseStatus;
  managerAndDirectReportsByUserAccountId: {
    [userAccountId: number]: ManagerAndDirectReports | null;
  };
  linkedTestTakerByUserAccountId: {
    [userAccountId: number]: TestTakerMatch | null;
  };
  invitationSuccessCount: null | {
    added: number;
    updated: number;
    deactivated: number;
  };
  timeToCompleteCSVUpload: number;
  gettingTestTakerForUserAccountIdStatus: responseStatus;
  getAllManagerAndDirectReportsStatus: responseStatus;
  uploadingUsersViaCSVStatus: responseStatus;
  getTimeToCompleteCSVUploadStatus: responseStatus;
  addingSingleUserStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: ExampleState = {
  revalidateBeforeMovingToNextStep: false,
  previewInviteUsersViaCSVStatus: "idle",
  uploadedData: {},
  errors: {},
  errorsByType: {},
  checkedManagerEmails: {},
  cvsUploadOptions: {
    addNewEmployees: 1,
  },
  previewDataResponse: {},
  emailCheckStatusByRowId: {},
  checkedEmails: {},
  activeCSVHeaders: [
    "firstName",
    "lastName",
    "jobTitle",
    "gender",
    "emailAddress",
    "managerEmailAddress",
  ],
  managerAndDirectReportsByUserAccountId: {},
  linkedTestTakerByUserAccountId: {},
  invitationSuccessCount: null,
  timeToCompleteCSVUpload: 0,
  gettingTestTakerForUserAccountIdStatus: "idle",
  getAllManagerAndDirectReportsStatus: "idle",
  uploadingUsersViaCSVStatus: "idle",
  getTimeToCompleteCSVUploadStatus: "idle",
  addingSingleUserStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
// ------------------------------------ GET Requests ------------------------------------

export const getManagerAndDirectReports = createAsyncThunk(
  "advancedCsvUpload/getManagerAndDirectReports",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/employeeRelationships`;
    const managerAndDirectReports = (await request(
      requestUrl
    )) as ManagerAndDirectReports;
    return { userAccountId, managerAndDirectReports };
  }
);

export const getAllManagerAndDirectReports = createAsyncThunk(
  "advancedCsvUpload/getAllManagerAndDirectReports",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/employeeRelationships`;
    const managerAndDirectReports = (await request(requestUrl)) as {
      [userAccountId: number]: ManagerAndDirectReports;
    };
    return managerAndDirectReports;
  },
  {
    condition: (_, { getState }) => {
      const {
        advancedCsvUpload: { getAllManagerAndDirectReportsStatus },
      } = getState() as RootState;
      return getAllManagerAndDirectReportsStatus === "idle";
    },
  }
);

export const getLinkedTestTaker = createAsyncThunk(
  "advancedCsvUpload/getLinkedTestTaker",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/linkedTestTaker`;
    const response = (await request(requestUrl)) as TestTakerMatch | {};

    const linkedTestTaker = (
      Object.keys(response).length ? response : null
    ) as TestTakerMatch | null;
    return { userAccountId, linkedTestTaker };
  }
);

export const getTimeToCompleteCSVUpload = createAsyncThunk(
  "advancedCsvUpload/getTimeToCompleteCSVUpload",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/timeToCompleteCSVUpload`;
    const timeToCompleteCSVUpload = (await request(
      requestUrl
    )) as TimeToCompleteCSVUploadResponse;
    return timeToCompleteCSVUpload?.timeToComplete || 0;
  }
);

// ------------------------------------ POST Requests ------------------------------------

export const previewInviteUsersViaCSV = createAsyncThunk(
  "advancedCsvUpload/previewInviteUsersViaCSV",
  async (
    payload: { rows?: StoredRows; options?: PreviewCSVUploadOptions },
    { getState }
  ) => {
    const {
      advancedCsvUpload: { cvsUploadOptions, uploadedData },
    } = getState() as RootState;
    const options = payload.options || cvsUploadOptions;
    const rows = payload.rows || uploadedData;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/previewUserCSVUpload`;
    const previewInviteUsersViaCSVResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ rows, options }),
    })) as NewPreviewCSVResponse;

    return { response: previewInviteUsersViaCSVResponse, options };
  }
);

export const uploadUsersViaCSV = createAsyncThunk(
  "advancedCsvUpload/uploadUsersViaCSV",
  async (
    payload: {
      rows?: StoredRows;
      options?: PreviewCSVUploadOptions;
      onSuccess?: () => void;
      sendInvitations?: boolean;
    },
    { getState, dispatch }
  ) => {
    const {
      advancedCsvUpload: {
        cvsUploadOptions,
        uploadedData,
        previewDataResponse,
      },
    } = getState() as RootState;
    const options = payload.options || cvsUploadOptions;
    const rows = payload.rows || uploadedData;

    const usersToAdd: RowData[] = [];
    const usersToUpdate: RowData[] = [];
    const usersToDeactivate: number[] =
      previewDataResponse?.usersToDeactivate ?? [];

    previewDataResponse.usersToAdd?.forEach((user) => {
      const rowData = rows[user.id];
      if (rowData) {
        usersToAdd.push(rowData);
      }
    });

    previewDataResponse.usersToUpdate?.forEach((user) => {
      const rowData = rows[user.id];
      if (rowData) {
        usersToUpdate.push(rowData);
      }
    });

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/uploadUsersViaCSV`;
    const uploadUsersViaCSVResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        usersToAdd,
        usersToUpdate,
        usersToDeactivate,
        sendInvitations: payload.sendInvitations,
      }),
    })) as NewPreviewCSVResponse;

    dispatch(setInviteModalOpen(false));
    dispatch(getTimeToCompleteCSVUpload());
    dispatch(getAllUsers());
    if (payload.onSuccess) {
      payload.onSuccess();
    }

    return {
      response: uploadUsersViaCSVResponse,
      options,
      invitationSuccessCount: {
        added: usersToAdd.length,
        updated: usersToUpdate.length,
        deactivated: usersToDeactivate.length,
      },
    };
  }
);

export const checkSingleUserEmail = createAsyncThunk(
  "advancedCsvUpload/checkSingleUserEmail",
  async (
    {
      emailAddress,
      rowId,
    }: {
      emailAddress: string;
      rowId?: number;
    },
    { getState }
  ): Promise<{
    isValid: boolean;
    emailAddress: string;
    errorType?: string;
    errorMessage?: string;
    rowId?: number; // this is needed if we want to show the cell loading
  }> => {
    const {
      advancedCsvUpload: { cvsUploadOptions, checkedEmails },
    } = getState() as RootState;

    if (checkedEmails[emailAddress]) {
      return {
        isValid: checkedEmails[emailAddress].isValid,
        errorType: checkedEmails[emailAddress].errorType,
        errorMessage: checkedEmails[emailAddress].errorMessage,
        emailAddress,
        rowId,
      };
    }
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/previewUserCSVUpload`;
    const previewInviteUsersViaCSVResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        rows: {
          0: {
            emailAddress,
            firstName: "Test",
            lastName: "Test",
          },
        },
        options: cvsUploadOptions,
      }),
    })) as NewPreviewCSVResponse;

    const error = previewInviteUsersViaCSVResponse.errors?.[0]?.emailAddress;

    return {
      isValid: !error,
      errorType: error?.[0].errorType,
      errorMessage: error?.[0].message,
      emailAddress,
      rowId,
    };
  },
  {
    condition: ({ emailAddress }, { getState }) => {
      // this is needed if we want to show the cell loading
      const {
        advancedCsvUpload: { checkedEmails },
      } = getState() as RootState;
      return !checkedEmails[emailAddress];
    },
  }
);
// ------------------------------------ PUT Requests ------------------------------------
export const updateManager = createAsyncThunk(
  "advancedCsvUpload/updateManager",
  async ({
    userAccountId,
    managerUserAccountId,
  }: {
    userAccountId: number;
    managerUserAccountId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/manager`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ managerUserAccountId }),
    });
    return { userAccountId, managerUserAccountId };
  }
);

// ------------------------------------ DELETE Requests ------------------------------------

export const removeManager = createAsyncThunk(
  "advancedCsvUpload/removeManager",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/manager`;
    await request(requestUrl, {
      method: "DELETE",
    });
    return { userAccountId };
  },
  {
    condition: (userAccountId, { getState }) => {
      const {
        advancedCsvUpload: { managerAndDirectReportsByUserAccountId },
      } = getState() as RootState;
      return !!managerAndDirectReportsByUserAccountId[userAccountId]
        ?.managerRelationship;
    },
  }
);

// ------------------------------------ Helper Functions ------------------------------------
export const getErrorsByType = createAsyncThunk(
  "advancedCsvUpload/getErrorsByType",
  (_, { getState }) => {
    const errorsByTypeSet: {
      [errorType: string]: Set<number>;
    } = {};
    const {
      advancedCsvUpload: { errors },
    } = getState() as RootState;

    Object.entries(errors).forEach(([rowId, rowErrors]) => {
      Object.keys(rowErrors).forEach((columnName) => {
        const errorType = rowErrors[columnName]?.[0].errorType;
        if (!errorType) return;
        if (!errorsByTypeSet[errorType]) {
          errorsByTypeSet[errorType] = new Set();
        }
        errorsByTypeSet[errorType].add(parseInt(rowId));
      });
    });

    const errorByTypesMap: ErrorByType = {};
    Object.entries(errorsByTypeSet).forEach(([errorType, rowIds]) => {
      errorByTypesMap[errorType] = Array.from(rowIds);
    });

    return errorByTypesMap;
  }
);

export const checkManagerEmail = createAsyncThunk(
  "advancedCsvUpload/checkManagerEmail",
  async (
    {
      emailAddress,
      rowId,
    }: {
      emailAddress: string;
      rowId?: number;
    },
    { getState }
  ): Promise<{
    isValid: boolean;
    emailAddress: string;
    rowId?: number; // this is needed if we want to show the cell loading
  }> => {
    const {
      global: { usersInfoById },
    } = getState() as RootState;

    const manager = Object.values(usersInfoById).find(
      (user) => user.emailAddress === emailAddress
    );

    return {
      isValid: !!manager,
      emailAddress,
      rowId,
    };
  },
  {
    condition: ({ emailAddress }, { getState }) => {
      // this is needed if we want to show the cell loading
      const {
        advancedCsvUpload: { checkedManagerEmails },
      } = getState() as RootState;
      return !checkedManagerEmails[emailAddress];
    },
  }
);

export const addSingleUser = createAsyncThunk(
  "advancedCsvUpload/addSingleUser",
  async (
    {
      firstName,
      lastName,
      emailAddress,
      jobTitle,
      gender,
      tmgRoleId,
      linkedTestTakerId,
      managerUserAccountId,
    }: {
      firstName?: string;
      lastName?: string;
      emailAddress?: string;
      gender?: string;
      jobTitle?: string;
      tmgRoleId?: 1 | 2;
      linkedTestTakerId?: number;
      managerUserAccountId?: number;
    },
    { dispatch }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users`;

    const inviteUserByEmailResponse = (await request(
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify({ emailAddresses: [emailAddress] }),
      },
      true
    )) as {
      createdUsers: [UserInfo];
      status: number;
    };

    const userToEditAccountId =
      inviteUserByEmailResponse.createdUsers[0].userAccountId;

    const editUserRequestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userToEditAccountId}`;
    const editPayload = {
      firstName,
      lastName,
      jobTitle,
      gender: gender?.toLowerCase(),
      tmgRoleId,
    };

    await request(editUserRequestUrl, {
      method: "PUT",
      body: JSON.stringify(editPayload),
    });

    await dispatch(
      updateUsersAction({
        [userToEditAccountId]: {
          emailAddress,
          tmgInvited: 1,
          userAccountId: userToEditAccountId,
          ...editPayload,
        },
      })
    );

    if (linkedTestTakerId) {
      await dispatch(
        linkTestTaker({
          userAccountId: userToEditAccountId,
          testTakerId: linkedTestTakerId,
        })
      );
    }

    if (managerUserAccountId) {
      await dispatch(
        updateManager({
          userAccountId: userToEditAccountId,
          managerUserAccountId: managerUserAccountId,
        })
      );
    }
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const exampleSlice = createSlice({
  name: "advancedCsvUpload",
  initialState,
  reducers: {
    clearPreviewInviteUsersViaCSVResponse: (state) => {
      state.previewInviteUsersViaCSVStatus =
        initialState.previewInviteUsersViaCSVStatus;
      state.uploadedData = initialState.uploadedData;
      state.errors = initialState.errors;
      state.errorsByType = initialState.errorsByType;
      state.activeCSVHeaders = initialState.activeCSVHeaders;
      state.checkedEmails = initialState.checkedEmails;
      state.checkedManagerEmails = initialState.checkedManagerEmails;
    },
    setUploadedData: (state, action: PayloadAction<StoredRows>) => {
      state.uploadedData = action.payload;
    },
    addUploadedData: (state, action: PayloadAction<StoredRows>) => {
      state.uploadedData = {
        ...state.uploadedData,
        ...action.payload,
      };
    },
    removeError: (
      state,
      action: PayloadAction<{
        rowId: number;
        columnName?: string;
      }>
    ) => {
      const { rowId, columnName } = action.payload;
      const newErrors = { ...state.errors };

      // If no columnName is provided, remove all errors for the row
      if (!columnName) {
        delete newErrors[rowId];
        state.errors = newErrors;
        return;
      }

      // If the row doesn't have any errors, return the state as is
      if (!newErrors[rowId]) {
        return state;
      }

      // Remove the error for the columnName
      delete newErrors[rowId][columnName];
      // Remove the row if it has no errors
      if (Object.keys(newErrors[rowId]).length === 0) {
        delete newErrors[rowId];
      }
      state.errors = newErrors;
    },
    addError: (
      state,
      action: PayloadAction<{
        rowId: number;
        columnName: string;
        errorType: string;
        message?: string;
      }>
    ) => {
      const { rowId, columnName } = action.payload;
      const message = action.payload.message || `${columnName} is required`;
      const newErrors = { ...state.errors };
      if (!newErrors[rowId]) {
        newErrors[rowId] = {};
      }
      newErrors[rowId][columnName] = [
        {
          message,
          errorType: action.payload.errorType,
        },
      ];
      state.errors = newErrors;
    },
    setRevalidateBeforeMovingToNextStep: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.revalidateBeforeMovingToNextStep = action.payload;
    },
    setActiveCSVUploadHeaders: (state, action: PayloadAction<string[]>) => {
      state.activeCSVHeaders = action.payload;
    },
    resetSuccessCount: (state) => {
      state.invitationSuccessCount = null;
    },
    decreaseCSVTimerByOne: (state) => {
      if (state.timeToCompleteCSVUpload > 0) {
        state.timeToCompleteCSVUpload = state.timeToCompleteCSVUpload - 1;
      }
    },
    removeTestTaker: (state, action: PayloadAction<number>) => {
      state.linkedTestTakerByUserAccountId[action.payload] = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(previewInviteUsersViaCSV.pending, (state) => {
        state.previewInviteUsersViaCSVStatus = "loading";
      })
      .addCase(
        previewInviteUsersViaCSV.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: NewPreviewCSVResponse;
            options: PreviewCSVUploadOptions;
          }>
        ) => {
          return {
            ...state,
            previewInviteUsersViaCSVStatus: "succeeded",
            previewDataResponse: {
              ...action.payload.response,
            },
            errors: action.payload.response.errors || {},
            cvsUploadOptions: action.payload.options || state.cvsUploadOptions,
          };
        }
      )
      .addCase(previewInviteUsersViaCSV.rejected, (state) => {
        state.previewInviteUsersViaCSVStatus = "failed";
      })
      .addCase(getErrorsByType.fulfilled, (state, action) => {
        state.errorsByType = action.payload;
      })
      .addCase(checkSingleUserEmail.pending, (state, action) => {
        if (action.meta.arg.rowId !== undefined) {
          state.emailCheckStatusByRowId[action.meta.arg.rowId] = "loading";
        }
      })
      .addCase(checkSingleUserEmail.fulfilled, (state, action) => {
        state.checkedEmails[action.payload.emailAddress] = {
          ...action.payload,
        };
        if (action.payload.rowId !== undefined) {
          state.emailCheckStatusByRowId[action.payload.rowId] = "succeeded";
        }
      })
      .addCase(checkSingleUserEmail.rejected, (state, action) => {
        if (action.meta.arg.rowId !== undefined) {
          state.emailCheckStatusByRowId[action.meta.arg.rowId] = "failed";
        }
      })
      .addCase(checkManagerEmail.fulfilled, (state, action) => {
        state.checkedManagerEmails[action.payload.emailAddress] = {
          isValid: action.payload.isValid,
        };
      })
      .addCase(
        getManagerAndDirectReports.fulfilled,
        (
          state,
          action: PayloadAction<{
            userAccountId: number;
            managerAndDirectReports: ManagerAndDirectReports;
          }>
        ) => {
          state.managerAndDirectReportsByUserAccountId[
            action.payload.userAccountId
          ] = action.payload.managerAndDirectReports;
        }
      )
      .addCase(getAllManagerAndDirectReports.pending, (state) => {
        state.getAllManagerAndDirectReportsStatus = "loading";
      })
      .addCase(getAllManagerAndDirectReports.rejected, (state) => {
        state.getAllManagerAndDirectReportsStatus = "failed";
      })
      .addCase(
        getAllManagerAndDirectReports.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            [userAccountId: number]: ManagerAndDirectReports;
          }>
        ) => {
          return {
            ...state,
            getAllManagerAndDirectReportsStatus: "succeeded",
            managerAndDirectReportsByUserAccountId: {
              ...state.managerAndDirectReportsByUserAccountId,
              ...payload,
            },
          };
        }
      )
      .addCase(getLinkedTestTaker.pending, (state) => {
        state.gettingTestTakerForUserAccountIdStatus = "loading";
      })
      .addCase(getLinkedTestTaker.rejected, (state) => {
        state.gettingTestTakerForUserAccountIdStatus = "failed";
      })
      .addCase(
        getLinkedTestTaker.fulfilled,
        (
          state,
          action: PayloadAction<{
            userAccountId: number;
            linkedTestTaker: TestTakerMatch | null;
          }>
        ) => {
          state.linkedTestTakerByUserAccountId[action.payload.userAccountId] =
            action.payload.linkedTestTaker;
          state.gettingTestTakerForUserAccountIdStatus = "succeeded";
        }
      )
      .addCase(
        updateManager.fulfilled,
        (
          state,
          {
            payload: { userAccountId, managerUserAccountId },
          }: PayloadAction<{
            userAccountId: number;
            managerUserAccountId: number;
          }>
        ) => {
          // first we will check if the user already had a manager, if so remove that user from their direct reports
          // then we will add the user to the new manager's direct reports
          // then we will update the user's manager
          const managerAndDirectReportsByUserAccountId = {
            ...state.managerAndDirectReportsByUserAccountId,
          };

          const currentUser =
            managerAndDirectReportsByUserAccountId[userAccountId];
          const oldManagerUserAccountId =
            currentUser?.managerRelationship?.managerUserAccountId;
          const oldManager =
            oldManagerUserAccountId &&
            state.managerAndDirectReportsByUserAccountId?.[
              oldManagerUserAccountId
            ];
          const newManager =
            state.managerAndDirectReportsByUserAccountId[managerUserAccountId];

          if (oldManager) {
            managerAndDirectReportsByUserAccountId[oldManagerUserAccountId] = {
              ...oldManager,
              directReportRelationships:
                oldManager.directReportRelationships?.filter(
                  (dr) => dr.employeeUserAccountId !== userAccountId
                ),
            };
          }

          const newRelationShip = {
            managerUserAccountId,
            employeeUserAccountId: userAccountId,
          };

          if (newManager) {
            managerAndDirectReportsByUserAccountId[managerUserAccountId] = {
              ...newManager,
              directReportRelationships: [
                ...(newManager.directReportRelationships || []),
                newRelationShip,
              ],
            };
          }

          managerAndDirectReportsByUserAccountId[userAccountId] = {
            ...currentUser,
            managerRelationship: newRelationShip,
          };
          return {
            ...state,
            managerAndDirectReportsByUserAccountId,
          };
        }
      )
      .addCase(
        removeManager.fulfilled,
        (
          state,
          {
            payload: { userAccountId },
          }: PayloadAction<{
            userAccountId: number;
          }>
        ) => {
          const managerAndDirectReportsByUserAccountId = {
            ...state.managerAndDirectReportsByUserAccountId,
          };

          const currentUser =
            state.managerAndDirectReportsByUserAccountId[userAccountId];
          const oldManagerUserAccountId =
            currentUser?.managerRelationship?.managerUserAccountId;
          const oldManager =
            oldManagerUserAccountId &&
            state.managerAndDirectReportsByUserAccountId[
              oldManagerUserAccountId
            ];

          if (oldManager) {
            managerAndDirectReportsByUserAccountId[oldManagerUserAccountId] = {
              ...oldManager,
              directReportRelationships:
                oldManager.directReportRelationships?.filter(
                  (dr) => dr.employeeUserAccountId !== userAccountId
                ),
            };
          }

          managerAndDirectReportsByUserAccountId[userAccountId] = {
            ...currentUser,
            managerRelationship: null,
          };

          return {
            ...state,
            managerAndDirectReportsByUserAccountId,
          };
        }
      )
      .addCase(
        uploadUsersViaCSV.fulfilled,
        (
          state,
          {
            payload: { invitationSuccessCount },
          }: PayloadAction<{
            invitationSuccessCount: {
              added: number;
              updated: number;
              deactivated: number;
            };
          }>
        ) => {
          toast.success(
            "CSV Invitations were successful",
            successNotificationOptions
          );
          return {
            ...state,
            invitationSuccessCount,
            uploadingUsersViaCSVStatus: "succeeded",
          };
        }
      )
      .addCase(uploadUsersViaCSV.pending, (state) => {
        state.uploadingUsersViaCSVStatus = "loading";
      })
      .addCase(uploadUsersViaCSV.rejected, (state) => {
        state.uploadingUsersViaCSVStatus = "failed";
      })
      .addCase(getTimeToCompleteCSVUpload.pending, (state) => {
        state.getTimeToCompleteCSVUploadStatus = "loading";
      })
      .addCase(getTimeToCompleteCSVUpload.rejected, (state) => {
        state.getTimeToCompleteCSVUploadStatus = "failed";
      })
      .addCase(
        getTimeToCompleteCSVUpload.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.getTimeToCompleteCSVUploadStatus = "succeeded";
          state.timeToCompleteCSVUpload = action.payload;
        }
      )
      .addCase(addSingleUser.fulfilled, (state) => {
        toast.success("User added successfully", successNotificationOptions);
        state.addingSingleUserStatus = "succeeded";
      })
      .addCase(addSingleUser.pending, (state) => {
        state.addingSingleUserStatus = "loading";
      })
      .addCase(addSingleUser.rejected, (state) => {
        state.addingSingleUserStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectPreviewCsvUploadStatus = (state: RootState) =>
  state.advancedCsvUpload.previewInviteUsersViaCSVStatus;
export const selectEmailCheckStatusByRowId = (state: RootState) =>
  state.advancedCsvUpload.emailCheckStatusByRowId;
export const selectUploadedData = (state: RootState) =>
  state.advancedCsvUpload.uploadedData;
export const selectPreviewDataResponse = (state: RootState) =>
  state.advancedCsvUpload.previewDataResponse;
export const selectErrors = (state: RootState) =>
  state.advancedCsvUpload.errors;
export const selectErrorsByType = (state: RootState) =>
  state.advancedCsvUpload.errorsByType;
export const selectCheckedEmails = (state: RootState) =>
  state.advancedCsvUpload.checkedEmails;
export const selectCvsUploadOptions = (state: RootState) =>
  state.advancedCsvUpload.cvsUploadOptions;
export const selectRevalidateBeforeMovingToNextStep = (state: RootState) =>
  state.advancedCsvUpload.revalidateBeforeMovingToNextStep;
export const selectCheckedManagerEmails = (state: RootState) =>
  state.advancedCsvUpload.checkedManagerEmails;
export const selectActiveCSVHeaders = (state: RootState) =>
  state.advancedCsvUpload.activeCSVHeaders;
export const selectManagerAndDirectReportsByUserAccountId = (
  state: RootState
) => state.advancedCsvUpload.managerAndDirectReportsByUserAccountId;
export const selectLinkedTestTakerByUserAccountId = (state: RootState) =>
  state.advancedCsvUpload.linkedTestTakerByUserAccountId;
export const selectGettingTestTakerForUserAccountIdStatus = (
  state: RootState
) => state.advancedCsvUpload.gettingTestTakerForUserAccountIdStatus;
export const selectUploadingUsersViaCSVStatus = (state: RootState) =>
  state.advancedCsvUpload.uploadingUsersViaCSVStatus;
export const selectInvitationSuccessCount = (state: RootState) =>
  state.advancedCsvUpload.invitationSuccessCount;
export const selectTimeToCompleteCSVUpload = (state: RootState) =>
  state.advancedCsvUpload.timeToCompleteCSVUpload;
export const selectGetTimeToCompleteCSVUploadStatus = (state: RootState) =>
  state.advancedCsvUpload.getTimeToCompleteCSVUploadStatus;
export const selectAddingSingleUserStatus = (state: RootState) =>
  state.advancedCsvUpload.addingSingleUserStatus;

// ------------------ Actions ------------------
export const {
  clearPreviewInviteUsersViaCSVResponse,
  setUploadedData,
  addUploadedData,
  removeError,
  addError,
  setRevalidateBeforeMovingToNextStep,
  setActiveCSVUploadHeaders,
  resetSuccessCount,
  decreaseCSVTimerByOne,
  removeTestTaker,
} = exampleSlice.actions;

export default exampleSlice.reducer;
