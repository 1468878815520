import { ReactNode, useState, useEffect } from "react";
import { Modal, Form, Card } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Select, { MultiValue } from "react-select";
import MultiInputWithTrashCan from "app/storybookComponents/Forms/MultiInputWithTrashCan";
import { UserInfo } from "app/containers/Global/types";
import { CompanySettings } from "../types";
import AvatarCircle from "app/components/AvatarCircle";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

interface Props {
  companySettings?: Partial<CompanySettings>;
  modalShowing: null | "Edit Domains" | "Who Can Invite" | "Who Can Create";
  hideModal: () => void;
  onSave: (payload: Partial<CompanySettings>) => void;
  loading?: boolean;
  users: { [userAccountId: string | number]: UserInfo };
}

export default function AdminConsoleSettingsModal({
  hideModal,
  modalShowing,
  companySettings,
  onSave,
  loading,
  users,
}: Props) {
  const [customDomainList, setCustomDomainList] = useState<string[]>([""]);
  const [userAccountIds, setUserAccountIds] = useState<number[]>([]);

  useEffect(() => {
    setCustomDomainList(companySettings?.customDomainList || [""]);
  }, [companySettings?.customDomainList]);

  useEffect(() => {
    if (modalShowing === "Who Can Create") {
      setUserAccountIds(companySettings?.teamCreationCustomList || []);
    }
  }, [companySettings?.teamCreationCustomList, modalShowing]);

  useEffect(() => {
    if (modalShowing === "Who Can Invite") {
      setUserAccountIds(companySettings?.invitationCustomList || []);
    }
  }, [companySettings?.invitationCustomList, modalShowing]);

  const getModalTitle = () => {
    switch (modalShowing) {
      case "Edit Domains":
        return "Add another domain";
      case "Who Can Invite":
        return "Who can invite";
      case "Who Can Create":
        return "Who can create";
      default:
        return null;
    }
  };
  const getModalContent = () => {
    switch (modalShowing) {
      case "Edit Domains":
        return getEditDomainsForm();
      case "Who Can Create":
      case "Who Can Invite":
        return getSelectForm();
      default:
        return null;
    }
  };

  const getSelectValue = () => {
    return userAccountIds.map((userAccountId) => {
      const user = users[userAccountId];
      const fullName = `${user.firstName} ${user.lastName}`;
      return {
        label: fullName,
        value: String(userAccountId),
        fullName,
        avatarCircle: (
          <AvatarCircle
            name={`${user.firstName} ${user.lastName}`}
            userAccountId={userAccountId}
            size="small"
          />
        ),
        emailAddress: user.emailAddress,
      };
    });
  };

  const customFilterOption = (
    option: FilterOptionOption<{
      label: string;
      value: string;
      avatarCircle: ReactNode;
      fullName: string;
      emailAddress: string;
    }>,
    inputValue: string
  ) => {
    const label = option.label.toLowerCase();
    const value = option.value.toLowerCase();
    const fullName = option.data.fullName.toLowerCase();
    const emailAddress = option.data.emailAddress.toLowerCase();
    const input = inputValue.toLowerCase();
    return (
      label.includes(input) ||
      value.includes(input) ||
      fullName.includes(input) ||
      emailAddress.includes(input)
    );
  };

  const onSelectChange = (
    newValue: MultiValue<{
      label: string;
      value: string;
      avatarCircle: ReactNode;
      fullName: string;
      emailAddress: string;
    }>
  ) => {
    const newValues = newValue.map((value) => Number(value.value));
    setUserAccountIds(newValues);
  };

  const getSelectForm = () => {
    const options: {
      label: string;
      value: string;
      avatarCircle: ReactNode;
      fullName: string;
      emailAddress: string;
    }[] = [];
    // We need to have all of the available users passed in as props and display them as options. Also check companySettings, if any user are already part of the list, we need to display them as selected options
    Object.keys(users).forEach((userAccountId) => {
      const user = users[userAccountId];
      const fullName = `${user.firstName} ${user.lastName}`;
      options.push({
        label: fullName,
        value: userAccountId,
        fullName,
        avatarCircle: (
          <AvatarCircle
            name={`${user.firstName} ${user.lastName}`}
            userAccountId={Number(userAccountId)}
            size="small"
          />
        ),
        emailAddress: user.emailAddress,
      });
    });

    return (
      <Select
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: null,
        }}
        // inputValue={inputText}
        isClearable
        isMulti
        isSearchable
        onChange={onSelectChange}
        // onInputChange={(newValue) => setInputText(newValue)}
        // onKeyDown={selectKeyDown}
        placeholder="Search by name or email"
        value={getSelectValue()}
        options={options}
        styles={{
          menu: (base: any) => ({
            ...base,
            marginTop: 0,
          }),
        }}
        formatOptionLabel={(memberInfo) => {
          return (
            <div className="member-option">
              {memberInfo.avatarCircle}
              <div className="member-info">
                <span className="member-name">{memberInfo.fullName}</span>
                <span className="member-email">{memberInfo.emailAddress}</span>
              </div>
            </div>
          );
        }}
        filterOption={customFilterOption}
      />
    );
  };

  const getEditDomainsForm = () => {
    return (
      <Card className="p-3 column-gap-20px">
        <MultiInputWithTrashCan
          description="Add, edit, or delete allowable domains for your custom list"
          inputs={customDomainList}
          setInputs={setCustomDomainList}
          placeHolder="@domain.com"
        />
      </Card>
    );
  };

  const handleSave = () => {
    switch (modalShowing) {
      // case if allowedDomains is empty, then set allowAllDomains to true.
      case "Edit Domains":
        return onSave({
          customDomainList,
        });

      case "Who Can Invite":
        return onSave({
          invitationCustomList: userAccountIds,
        });
      case "Who Can Create":
        return onSave({
          teamCreationCustomList: userAccountIds,
        });
      default:
        return null;
    }
  };

  return (
    <Modal
      show={modalShowing !== null}
      onHide={hideModal}
      className="simple-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>{getModalTitle()}</h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none" }}
          xIcon
        />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        {getModalContent()}
        <div
          style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}
        >
          <Button type="submit" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
