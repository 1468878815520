import { SkillGuideNavSchema } from "./types";

export const SKILL_GUIDE_TEMPLATE = {
  Overview: [
    {
      title: "Introduction",
      key: "introduction",
      icon: "bookmark",
    },
    {
      title: "Why This Matters",
      key: "whyThisMatters",
      icon: "bullseye-arrow",
    },
    {
      title: "Examples",
      key: "examples",
      icon: "users",
      isList: true,
    },
  ],
  Tips: [
    {
      title: "Some Things To Do",
      key: "someThingsToDo",
      icon: "clipboard-check",
      isList: true,
    },
    {
      title: "Some Things To Avoid",
      key: "someThingsToAvoid",
      icon: "triangle-exclamation",
      isList: true,
    },
  ],
  Reflection: [
    {
      title: "Reflective Questions",
      key: "reflectiveQuestions",
      icon: "question",
      isList: true,
    },
    {
      title: "Team Questions",
      key: "teamQuestions",
      icon: "messages",
      isList: true,
    },
  ], // Commenting out for now -- may re-add later
  // Summary: [
  //   {
  //     title: "Summary",
  //     key: "inSummary",
  //   },
  // ],
} as SkillGuideNavSchema;

export const CONSTRUCT_NEXT_STEPS_KEY = "Next Steps";
export const CONSTRUCT_SKILLS_IN_THIS_AREA_KEY = "Skills In This Area";

export const CONSTRUCTS_GUIDE_TEMPLATE = {
  Overview: [
    {
      title: "Introduction",
      key: "introduction",
      icon: "bookmark",
    },
    {
      title: "Why This Matters",
      key: "whyThisMatters",
      icon: "bullseye-arrow",
      isList: true,
    },
    {
      title: "What success looks like",
      key: "whatSuccessLooksLike",
      icon: "trophy",
      isList: true,
    },
  ],
  Tips: [
    {
      title: "Tips for being great at this",
      key: "tipsForBeingGreatAtThis",
      icon: "clipboard-check",
      isList: true,
    },
    {
      title: "Common Mistakes",
      key: "commonMistakes",
      icon: "triangle-exclamation",
      isList: true,
    },
    {
      title: "To Build On Strengths",
      key: "buildOnStrengths",
      icon: "dumbbell",
      isList: true,
      hasBoldedHeader: true,
    },
    {
      title: "To Address Weaknesses",
      key: "addressWeaknesses",
      icon: "scale-unbalanced",
      isList: true,
      hasBoldedHeader: true,
    },
    {
      title: "Action Steps",
      key: "actionSteps",
      icon: "messages",
      isList: true,
    },
  ],
  [CONSTRUCT_SKILLS_IN_THIS_AREA_KEY]: [],
  "Next Steps": [
    {
      title: "Guide for Team Leaders",
      key: "guideForTeamLeaders",
      icon: "user",
      isList: true,
    },
    {
      title: "Guide for Team Members",
      key: "guideForTeamMembers",
      icon: "users",
      isList: true,
    },
  ],
} as SkillGuideNavSchema;

export const TEAM_360_FACTOR_ID_TO_NAME_MAP = {
  1: "Target",
  2: "Empower",
  3: "Align",
  4: "Monitor",
} as { [id: number]: string };

export const SKILLS_COACHBOT_PROMPT: {
  [key: string]: {
    coachBotIntro: string;
    preSelectedPrompts: string[];
    chatType: string;
  };
} = {
  Skill: {
    coachBotIntro: "How can I help you with this skill?",
    preSelectedPrompts: [
      "Help me practice this skill",
      "Help me understand this skill better",
      "Help me connect this skill to an existing meeting or team routine",
    ],
    chatType: "skillGuide",
  },
  Dimension: {
    coachBotIntro: "How can I help you with this dimension?",
    preSelectedPrompts: [
      "Help me practice this dimension",
      "Help me understand this dimension better",
      "Help me connect this dimension to an existing meeting or team routine",
    ],
    chatType: "dimensionGuide",
  },
};
