import { Form } from "react-bootstrap";
import SimpleModal from "app/components/Modals/SimpleModal";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import Button from "app/storybookComponents/Button";
import TextArea from "app/storybookComponents/Forms/TextArea";
import TextInput from "app/storybookComponents/Forms/TextInput";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import InviteUserForm from "app/storybookComponents/Forms/InviteUserForm";
import {
  createTeam,
  inviteUserByEmail,
  resetInvalidInvitedStrings,
  selectAllCompanyUsersById,
  selectCreateTeamStatus,
  selectInvalidInvitedStrings,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import TeamAverageIllustration from "resources/images/illustration-team-average.png";
import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import {
  selectCreatedTeamId,
  setCreatedTeamId,
} from "app/components/Modals/slice";
import { showScheduleAssessmentModalForTeamId } from "app/components/LaunchAssessmentModal/slice";
import { selectUserIsAbleToInviteUsers } from "app/containers/UserGuide/slice";

interface Props {
  show?: boolean;
  startingStep?: number;
  onHide: () => void;
  userFirstName?: string;
  allowedDomains?: string[] | "ALL";
  defaultDepartmentId?: number | null;
}

export default function TeamLeaderCreateTeamOnboardingModal({
  startingStep = 0,
  show,
  onHide,
  userFirstName,
  allowedDomains,
  defaultDepartmentId,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const departments = useAppSelector(selectDepartments);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const creatingTeamStatus = useAppSelector(selectCreateTeamStatus);
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);
  const createdTeamId = useAppSelector(selectCreatedTeamId);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);

  const [sendEmails, setSendEmails] = useState(true);
  const [activeStep, setActiveStep] = useState<1 | 2 | 3 | 4>(1); // Step 4 will be actually the success page
  const [teamName, setTeamName] = useState<string | null>(null);
  const [teamDescription, setTeamDescription] = useState<string | null>(null);
  const [departmentId, setDepartmentId] = useState<null | number>(null);

  const relativeActiveStep = startingStep + activeStep;

  useEffect(() => {
    if (!show) return;
    if (!userFirstName) {
      setTeamName(null);
    } else {
      setTeamName(`${userFirstName}’s Team`);
    }
  }, [userFirstName, show]);

  useEffect(() => {
    if (!show) return;
    if (defaultDepartmentId) {
      setDepartmentId(defaultDepartmentId);
    }
  }, [defaultDepartmentId, show]);

  const getModalTitle = () => {
    switch (activeStep) {
      case 1:
        return `Welcome ${userFirstName}, Let’s Create Your First Team`;
      case 2:
        return "Short Team Description";
      case 3:
        return `Invite Your Team Members to ${teamName}`;
      case 4:
        return "Team Created Successfully";
    }
  };

  const onCloseModal = () => {
    setActiveStep(1);
    setTeamName(null);
    setTeamDescription(null);
    setDepartmentId(null);
    dispatch(resetInvalidInvitedStrings());
    dispatch(setCreatedTeamId(null));
    onHide();
  };

  const getModalBody = () => {
    if (creatingTeamStatus === "loading") {
      return <div>Creating team now, please wait...</div>;
    }

    switch (activeStep) {
      case 1: {
        const departmentOptions = Object.entries(departments).map(
          ([key, val]) => ({
            label: val.name,
            value: Number(key),
          })
        );
        return (
          <>
            <div className="snapshot-box">
              <p>
                Teams should represent stable, recognizable groups working
                towards important organizational goals. We recommend forming
                teams that will be together for 6-12 months, focusing on
                enhancing teamwork and productivity. Avoid creating teams in TI
                for purely social purposes.
              </p>
            </div>
            <div className="column-gap-20px">
              <TextInput
                inputLabel="Team Name"
                placeholder="Enter team name"
                maxLength={50}
                onTextChange={(newTeamName) => setTeamName(newTeamName)}
                inputText={teamName ?? ""}
                controlId="teamName"
                className="column"
              />
              <Form.Group className="form-group">
                <Form.Label className="textarea-label">Department</Form.Label>
                <Select
                  options={departmentOptions}
                  onChange={(e) => {
                    setDepartmentId(e?.value ?? null);
                  }}
                  value={departmentOptions.find(
                    (option) => option.value === departmentId
                  )}
                />
              </Form.Group>
            </div>

            <div className="text-end">
              <Button
                onClick={() => setActiveStep(2)}
                disabled={!teamName?.trim()}
              >
                Next
              </Button>
            </div>
          </>
        );
      }
      case 2: {
        return (
          <>
            <TextArea
              inputLabel="What does this team do?"
              placeholder="Our team creates cool products that delight customers and drive revenue growth in the post-hire space."
              maxLength={250}
              onTextChange={(newTeamName) => setTeamDescription(newTeamName)}
              inputText={teamDescription ?? ""}
              controlId="teamDescription"
            />
            <div className="text-end">
              <Button
                onClick={async () => {
                  const resp = (await dispatch(
                    createTeam({
                      payload: {
                        teamName: teamName ?? "",
                        departmentId: departmentId ?? 0,
                        teamDescription: teamDescription ?? "",
                        teamMemberEmails: [],
                      },
                      userAccountId: userAccountId ?? 0,
                    })
                  )) as any;
                  if (resp.error) return;
                  setActiveStep(3);
                }}
                disabled={!teamDescription?.trim()}
              >
                Next
              </Button>
            </div>
          </>
        );
      }
      case 3: {
        const bulletPointText = [
          "They will receive an email inviting them to Develop by Criteria",
          "They will be asked to create their user profile and join your team",
          "Once they’ve joined, they will be able to share their personality, take the TEAMscan survey and a lot more",
          "This usually takes about 5 minutes",
          "If you want to add more members later on, you can do this on the home page or on your team guide",
        ];
        return (
          <>
            <div>
              <p>
                <b>When you invite in your team members:</b>
              </p>
              <ul>
                {bulletPointText.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <InviteUserForm
                onInviteViaEmail={async (emailAddresses: string[]) => {
                  dispatch(resetInvalidInvitedStrings());
                  const resp = (await dispatch(
                    inviteUserByEmail({
                      emailAddresses,
                      teamId: createdTeamId ?? undefined,
                      sendInvitations: sendEmails,
                    })
                  )) as any;
                  if (resp.error) return;
                  setActiveStep(4);
                }}
                teamMembers={Object.values(usersInfoById)}
                allowedDomains={allowedDomains}
                invalidInvitedStrings={invalidInvitedStrings}
                teamId={createdTeamId ?? undefined}
                onCSVUploadSuccess={() => {
                  setActiveStep(4);
                }}
                hideCsvTab
                isUserAllowedToInviteNewMembers={canUserInviteNewMembers}
                resetInvalidInvitedStrings={() =>
                  dispatch(resetInvalidInvitedStrings())
                }
                setSendEmailSettingProps={{
                  setter: setSendEmails,
                  value: sendEmails,
                }}
              />
            </Form>
          </>
        );
      }
      case 4: {
        const totalTeamMembersInvited =
          teamsById[createdTeamId ?? 0]?.pendingTeamMemberIds?.length ?? 0;

        return (
          <div className="column-gap-16px text-center">
            <div>
              <img src={TeamAverageIllustration} alt="Teams" />
            </div>
            <p>
              <b>“{teamName}” has been successfully created</b>
            </p>
            <p>
              {totalTeamMembersInvited} team member
              {`${totalTeamMembersInvited === 1 ? "" : "s"}`} have been invited.
              Invited users will receive an email with an invitation to join
              Develop by Criteria. Once they are logged in, they will be able to
              view this team.
            </p>
            <div className="row-gap-16px justify-content-center">
              <Button
                onClick={() => {
                  navigate(`/TeamGuide/${createdTeamId}`);
                }}
              >
                Go to Team Guide
              </Button>
              <Button
                variant="secondary-blue"
                onClick={() => {
                  if (!createdTeamId) return;
                  onCloseModal();
                  dispatch(showScheduleAssessmentModalForTeamId(createdTeamId));
                }}
              >
                Launch TEAMscan
              </Button>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <SimpleModal
      show={show}
      onHide={onCloseModal}
      title={getModalTitle()}
      titleSmallText={
        activeStep <= 3
          ? `Step ${relativeActiveStep} of ${startingStep + 3}`
          : "Done"
      }
    >
      {getModalBody()}
    </SimpleModal>
  );
}
