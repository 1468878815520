import { getAllowedDomains } from "app/containers/AdminConsole/helpers";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";
import InviteUserForm from "app/storybookComponents/Forms/InviteUserForm";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  inviteUserByEmail,
  selectInviteUserByEmailStatus,
  selectAllCompanyUsersById,
  selectInvalidInvitedStrings,
  resetInvalidInvitedStrings,
} from "app/containers/Global/slice";
import { useEffect, useState } from "react";
import { selectUserIsAbleToInviteUsers } from "app/containers/UserGuide/slice";

interface Props {
  onSuccessfulInvite: () => void;
  departmentId?: number | null;
  teamId?: number | null;
}

const InviteTeamLeader = ({
  onSuccessfulInvite,
  departmentId,
  teamId,
}: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const companySettings = useAppSelector(selectCompanySettings);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const inviteUsersByEmailStatus = useAppSelector(
    selectInviteUserByEmailStatus
  );
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const [sendEmails, setSendEmails] = useState(true);
  // If no departmentId is passed then we need to show a dropdown to show all of the departments and allow the user to select one

  // Upon unmount we need to reset the invalid invited strings
  useEffect(() => {
    return () => {
      dispatch(resetInvalidInvitedStrings());
    };
  }, [dispatch]);

  const onInviteViaEmail = async (emailAddresses: string[]) => {
    dispatch(resetInvalidInvitedStrings());
    const ti_onboardingRoleId = departmentId ? 3 : 2;

    // Create an async call to invite via email, upon a successful response show a modal or fire an event
    const resp = (await dispatch(
      inviteUserByEmail({
        emailAddresses,
        ti_onboardingRoleId,
        ti_onboardingDepartmentId: departmentId ?? undefined,
        teamId: teamId ?? undefined,
        sendInvitations: sendEmails,
      })
    )) as any;

    // If there was an error we should not call the onSuccessfulInvite function
    if (resp.error) return;
    onSuccessfulInvite();
  };

  return (
    <>
      <p className="mb-0">
        <b>Why Invite Team Leaders?</b> When you invite managers, they can
        create teams, ensure their members take the TEAMscan survey, and receive
        teamwork insight reports for growth. You'll also get a department-level
        report to track your department's progress.
      </p>
      <InviteUserForm
        onInviteViaEmail={onInviteViaEmail}
        inviteMemberInfoMessage="Simply type in the email addresses of the team leaders you want to invite. They'll receive an invitation to join the platform."
        teamMembers={Object.values(usersInfoById)}
        allowedDomains={getAllowedDomains(companySettings)}
        isLoading={inviteUsersByEmailStatus === "loading"}
        invalidInvitedStrings={invalidInvitedStrings}
        onCSVUploadSuccess={() => {
          onSuccessfulInvite();
        }}
        hideCsvTab
        hideInviteViaLinkTab={!canUserInviteNewMembers}
        resetInvalidInvitedStrings={() =>
          dispatch(resetInvalidInvitedStrings())
        }
        setSendEmailSettingProps={{
          setter: setSendEmails,
          value: sendEmails,
        }}
      />
    </>
  );
};

export default InviteTeamLeader;
