import { createEntityAdapter } from "@reduxjs/toolkit";
import {
  TeamInvitationNotification,
  PendingAssessmentNotification,
  AssessmentResultsNotification,
  TeamCreationNotification,
  AddTeamMemberNotification,
  SendTeamScanReminderNotification,
  LaunchTeamScanNotification,
  NewDirectReportNotification,
} from "./types";

export const teamInvitationAdaptor =
  createEntityAdapter<TeamInvitationNotification>({
    selectId: (e) => e.teamId,
    sortComparer: (a, b) => a.notificationId - b.notificationId, // This is what decides the order of entites in the array
  });

export const pendingAssessmentNotificationAdaptor =
  createEntityAdapter<PendingAssessmentNotification>({
    selectId: (e) => e.teamId,
    sortComparer: (a, b) => b.assessmentEventId - a.assessmentEventId, // This is what decides the order of entites in the array
  });

export const pendingAssessmentResultsNotificationAdaptor =
  createEntityAdapter<AssessmentResultsNotification>({
    selectId: (e) => e.notificationId,
  });

export const teamCreationNotificationAdaptor =
  createEntityAdapter<TeamCreationNotification>({
    selectId: (e) => e.notificationId,
  });

export const addTeamMemberNotificationAdaptor =
  createEntityAdapter<AddTeamMemberNotification>({
    selectId: (e) => e.notificationId,
  });

export const sendTeamScanReminderNotificationAdaptor =
  createEntityAdapter<SendTeamScanReminderNotification>({
    selectId: (e) => e.notificationId,
  });

export const launchTeamScanNotificationAdaptor =
  createEntityAdapter<LaunchTeamScanNotification>({
    selectId: (e) => e.notificationId,
  });

export const newDirectReportAdaptor =
  createEntityAdapter<NewDirectReportNotification>({
    selectId: (e) => e.notificationId,
  });
