import jwtDecode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";
import CryptoJS from "crypto-js";
import {
  REACT_APP_HIRESELECT_URL,
  REACT_APP_URL,
  sskowk,
  ENVIRONMENT,
} from "utils/environmentVariables";

// Question: Is there a better way to store these global interfaces? Maybe into its own types file
declare global {
  interface DecodedJWT {
    iss: string;
    jti: number;
    iat: number;
    nbf: number;
    exp: number;
    sub: number; // userAccountId
    scopes: string[];
  }
  interface Window {
    analytics: any;
    Appcues: {
      identify: (userId: string, userProperties: object) => void;
      reset: () => void;
    };
  }
}

// Determines if the critToken cookie is valid, i.e. comes from the same instance/issuer
// in case the user has previously logged into another Criteria instance in another tab/window.
// If it is not valid, we should not consider the user authenticated for this instance.
// Note: critTokenIssuer will be the HireSelect url for whichever instance it was issued from
// ex. 'https://hireselect.criteriacorp.com'
export const isValidCritToken = (critToken?: string | null) => {
  if (critToken) {
    const decodedToken: DecodedJWT = jwtDecode(critToken);
    const critTokenIssuer = decodedToken.iss;

    // if the hostname is localhost, the available critToken can only be a cookie set when
    // hireselect2 is running in localhost, so we can make an exception here
    if (
      new URL(`${REACT_APP_URL}`).hostname === "localhost" ||
      REACT_APP_HIRESELECT_URL === critTokenIssuer
    ) {
      return critToken;
    }
  }

  return null;
};

// Copied code from original talent insights
export const downloadCSV = (tableName: string, data: string[][]) => {
  // this version of the function should work for all browsers
  const csv = convertArrayOfArraysToCSV(data);
  if (csv === null) {
    return null;
  }

  const filename = `${tableName}.csv`;

  const BOM = new Uint8Array([0xef, 0xbb, 0xbf]); // Enforces UTF-8 encoding
  const blob = new Blob([BOM, csv], { type: "text/csv;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection, Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// Copied code from original talent insights
const convertArrayOfArraysToCSV = (data: string[][]) => {
  if (data == null || !data.length) {
    return null;
  }

  const columnDelimiter = ",";
  const lineDelimiter = "\n";

  let result = "";
  data.forEach((row, idx) => {
    row.forEach((item, idy) => {
      // Add a comma to the beginning of each cell unless its the first one
      if (idy > 0) {
        result += columnDelimiter;
      }

      let itemToDisplay = item;
      if (
        item === null ||
        item === undefined ||
        !(typeof item === "string" || typeof item === "number")
      ) {
        itemToDisplay = "-";
      }
      // itemToDisplay = itemToDisplay.replace(/"/g, "'");

      result += `"${itemToDisplay}"`;
    });

    if (idx < data.length - 1) {
      result += lineDelimiter;
    }
  });
  return result;
};

export const setCookieItem = (
  key: string,
  value: string,
  numberOfDays: number
) => {
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${value};     expires=${now.toUTCString()}; path=/`;
};

export const getUserIdFromCookie = () => {
  try {
    // Getting the token from the url should only be temporary up until we have the proper domain set up.
    const queryParams = new URLSearchParams(window.location.search);
    // const token = queryParams.get('critToken') || Cookies.get('critToken');
    const token =
      queryParams.get("critToken") ?? localStorage.getItem("tmg-tkn");
    if (!token) {
      throw new Error();
    }
    // Cookies.set('critToken', token);
    const decodedToken: DecodedJWT = jwtDecode(token);
    localStorage.setItem("tmg-tkn", token);
    return decodedToken.sub;
    // Commenting this out temporarily because to see if it fixes the issue where it kicks the user out breifly.
    // If the token is not inside of the cookie or local storage lets make sure to add them to be safe
    // if (
    //   !(Cookies.get('critToken') || localStorage.getItem('tmg-tkn')) &&
    //   token
    // ) {
    // }
  } catch (e) {
    return null;
  }
};

export const logUserOut = (redirect: boolean = true) => {
  // Cookies.remove('critToken');
  localStorage.clear();
  window.Appcues?.reset();
  if (redirect) {
    window.location.href = `/`;
  }
};

// Generated using openAI's GPT-3, retuns a random number between min and max but skweed the distribution towards the min
export function generateNumber(min: number, max: number) {
  let rand = Math.random();
  let num = Math.round(min + rand * rand * max - 1);
  return num;
}

// Wraps the entries in a generic to provide typescript support
type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

export const calculateDaysInBetween = (
  date1: string,
  date2?: string
): number => {
  const date_1 = new Date(date1).valueOf();
  const date_2 = date2 ? new Date(date1).valueOf() : Date.now();
  const diff = date_2 - date_1;
  return Math.ceil(diff / (1000 * 3600 * 24));
};
export const getSelectProps = (
  type?: "search",
  customStyles?: { fixedWidth?: number; controlBgColor?: string }
) => {
  switch (type) {
    case "search":
    default: {
      const { fixedWidth, controlBgColor } = customStyles || {};
      return {
        selectStyles: {
          control: (base: any) => ({
            ...base,
            flexDirection: "row-reverse",
            width: fixedWidth,
            background: controlBgColor,
          }),
          clearIndicator: (base: any) => ({
            ...base,
            position: "absolute",
            right: 0,
          }),
          menu: (base: any) => ({
            ...base,
            position: "absolute",
            right: 0,
            width: "100%",
            zIndex: 9999,
          }),
        },
        components: {
          IndicatorSeparator: () => null,
          DropdownIndicator: (props: any) =>
            components.DropdownIndicator && (
              <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon="search" />
              </components.DropdownIndicator>
            ),
        },
      };
    }
  }
};

// Complexity: O(n * m)
export const newCombineArrays = (...arrayOfArrays: any[]): any[] => {
  const max: number = arrayOfArrays.reduce(
    (acc, curr) => Math.max(acc, curr.length),
    0
  );
  const returnArr: any[] = [];

  for (let i = 0; i < max; i++) {
    arrayOfArrays.forEach((arrayElm) => {
      if (arrayElm[i] !== undefined) {
        returnArr.push(arrayElm[i]);
      }
    });
  }

  return returnArr;
};

export const decodeStringArray = (array: string) => {
  try {
    return JSON.parse(array);
  } catch (e) {
    console.log("error:", e);
    return [];
  }
};

export const isValidEmail = (email: string = "") => {
  // Regular expression to validate email format
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailRegex.test(email);
};

// create a function that takes in a string and it return back the string but with the first letter capitalized
export const capitalizeWord = (word: string = ""): string => {
  if (!word) return "";
  return word[0].toUpperCase() + word.slice(1);
};

export const isInternalCompanyAccount = (companyAccountId?: number) => {
  const INTERNAL_TESTING_COMPANY_ACCOUNTS = [69669, 236473];
  if (
    companyAccountId &&
    INTERNAL_TESTING_COMPANY_ACCOUNTS.includes(companyAccountId)
  ) {
    return true;
  }
  return false;
};

export const getHasAccessToChatbot = (companyAccountId?: number) => {
  if (ENVIRONMENT === "dev") {
    return true;
  }
  if (ENVIRONMENT === "prod") {
    return isInternalCompanyAccount(companyAccountId);
  }
};

/**
 * Decrypt string.
 *
 * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
 * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
 * @link https://github.com/CriteriaCorp/new-oda/blob/master/src/utils/Encryption.js Reference.
 * @param string encryptedString The encrypted string to be decrypt.
 * @param string key The key.
 * @return string Return decrypted string.
 */

// this function will take in a string and decrypt it using the key "sskowk" from environment variables
export const decryptSurveyInfo = (
  encryptedString: string,
  key: string = sskowk
) => {
  const ENCRYPT_METHOD = "AES-256-CBC" as const;
  const aesNumber = ENCRYPT_METHOD.match(/\d+/)?.[0];
  let encryptMethodLength = parseInt(aesNumber ?? "0");
  encryptMethodLength = encryptMethodLength / 4;

  // Parse the encrypted data
  const json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
  );

  // Parse the salt and IV
  const salt = CryptoJS.enc.Hex.parse(json.salt);
  const iv = CryptoJS.enc.Hex.parse(json.iv);

  const encrypted = json.ciphertext; // no need to base64 decode.
  const iterations = parseInt(json.iterations) || 999;

  const hashKey = CryptoJS.PBKDF2(key, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

export const handleKeyDown = (
  e: React.KeyboardEvent,
  handleClick?: () => void
) => {
  if (e.key === "Enter" || e.key === " ") {
    // Perform the same action as onClick
    handleClick?.();
  }
};

export function toSorted<T>(
  array: T[],
  compareFn?: (a: T, b: T) => number
): T[] {
  return [...array].sort(compareFn);
}

export const getS = (count: number) => (count === 1 ? "" : "s");

export const getHasOrHave = (count: number) => (count === 1 ? "has" : "have");

export function numberToWord(number: number): string {
  const words = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ];
  const isPositive = number >= 0;
  if (isPositive && number < words.length) {
    return words[number];
  }

  return number.toString();
}

export const isUTCTimeBeforeOrAfterLocal = (
  utcTimeString: string
): "before" | "equal" | "after" => {
  // Create a Date object for the current local time
  const localTime = new Date();

  // Create a Date object for the UTC time string
  // Assuming the UTC time string is for today's date
  const today = localTime.toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const utcDateTimeString = `${today}T${utcTimeString}Z`; // Construct the full UTC date-time string
  const utcTimeConvertedToLocal = new Date(utcDateTimeString);

  if (utcTimeConvertedToLocal < localTime) {
    return "before";
  } else if (utcTimeConvertedToLocal > localTime) {
    return "after";
  } else {
    return "equal";
  }
};
