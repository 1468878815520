import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import Loading from "app/storybookComponents/Loading";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { sendReminder } from "app/containers/Dashboard/slice";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import {
  getAssessmentInformation,
  selectDepartments,
  selectIsDepartmentsHidden,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import { selectTeamsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import {
  getTeamPersonalityReports,
  getTeamPersonalityScores,
  remindMembersFromTeamToTakePersonalityTest,
  selectAllTeamPersonalityReports,
  selectAllTeamPersonalityScores,
} from "app/components/WorkplaceInsightsReport/slice";
import {
  acceptInvitation,
  declineInvitation,
  dismissNotification,
  selectAllAddTeamMemberNotifications,
  selectAllAssessmentResultsNotifications,
  selectAllLaunchTeamScanNotifications,
  selectAllNewDirectReportNotifications,
  selectAllPendingAssessmentsNotifications,
  selectAllSendTeamScanReminderNotifications,
  selectAllTeamCreationNotifications,
  selectAllTeamInvitations,
  selectDismissedNotificationIds,
  selectGetNotificationsStatus,
} from "./slice";
import InviteUsersModal from "../Modals/InviteUsersModal";
import {
  openCreateTeamModal,
  setIsNewDirectReportModalOpen,
} from "../Modals/slice";
import { setIsNotificationDropdownOpen } from "../GlobalNavbar/slice";
import { getTeamName } from "./helpers";
import EditSurveyModal from "app/containers/AdminConsole/Modals/EditSurveyModal";
import { getDaysRemaining } from "app/containers/Assessment/helpers";
import CardContainer from "./CardContainer";

type TActionCard = {
  title: string;
  description: string | JSX.Element;
  primaryButton?: {
    label: string;
    onClick: () => void;
  };
  secondaryButton?: {
    label: string;
    onClick: () => void;
  };
  daysLeft?: number;
};

export default function TodoCardV2() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getNotificationsStatus = useAppSelector(selectGetNotificationsStatus);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const departmentsById = useAppSelector(selectDepartments);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const allAssessmentResultsNotifications = useAppSelector(
    selectAllAssessmentResultsNotifications
  );
  const allTeamCreationNotifications = useAppSelector(
    selectAllTeamCreationNotifications
  );
  const dismissedNotificationIds = useAppSelector(
    selectDismissedNotificationIds
  );
  const allAddTeamMemberNotifications = useAppSelector(
    selectAllAddTeamMemberNotifications
  );
  const allSendTeamScanReminderNotifications = useAppSelector(
    selectAllSendTeamScanReminderNotifications
  );
  const allLaunchTeamScanNotifications = useAppSelector(
    selectAllLaunchTeamScanNotifications
  );
  const allNewDirectReportNotifications = useAppSelector(
    selectAllNewDirectReportNotifications
  );
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const canUserInviteUsers = useAppSelector(selectUserIsAbleToInviteUsers);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const allTeamPersonalityReports = useAppSelector(
    selectAllTeamPersonalityReports
  );
  const allTeamPersonalityScores = useAppSelector(
    selectAllTeamPersonalityScores
  );
  const currentUserInfo = useMemo(
    () => (currentUserAccountId ? usersById[currentUserAccountId] : null),
    [currentUserAccountId, usersById]
  );

  useEffect(() => {
    dispatch(getAssessmentInformation());
  }, [dispatch]);

  // ---------------------- State ----------------------
  const [showInviteMemberModal, setShowInviteMemberModal] = useState<
    number | boolean
  >(false);
  const [showRemindWarningModal, setShowRemindWarningModal] = useState<null | {
    teamId: number;
    notificationId?: number;
  }>(null);
  const [showRemindTeamToTakeEPP, setShowRemindTeamToTakeEPP] = useState<{
    teamId: number;
  } | null>(null);
  const [showing, setShowing] = useState<{
    teamOrDepartment: "team" | "department";
    id: number;
    isLeader?: boolean;
  } | null>(null);
  const [extendExpirationWarningModal, setExtendExpirationWarningModal] =
    useState<false | number>(false);

  // ---------------------- Handlers ----------------------
  const onSeeAll = () => {
    dispatch(setIsNotificationDropdownOpen(true));
  };

  const onTeamDropdownClick = (teamId: number, isLeader: boolean) => {
    setShowing({
      teamOrDepartment: "team",
      id: teamId,
      isLeader,
    });

    // Will then check if the team pesonality scores are fetched for team. if not fetch them
    dispatch(getTeamPersonalityScores({ teamId }));
    // Will then check if the team pesonality report is fetched for team. if not fetch them
    if (!allTeamPersonalityReports[teamId]) {
      dispatch(getTeamPersonalityReports(teamId));
    }
  };

  // ---------------------- Getters ----------------------
  const getDropdownItem = ({
    label,
    description,
    eventKey,
    onClick,
  }: {
    label: string;
    description?: string;
    eventKey?: string;
    onClick?: () => void;
  }) => (
    <Dropdown.Item eventKey={eventKey} onClick={onClick}>
      <p className="sapphire-150-text">{label}</p>
      {description ? (
        <p className="text-grey small-body-text">{description}</p>
      ) : null}
    </Dropdown.Item>
  );

  const getTeamDropdownItems = (): JSX.Element[] => {
    const teamIdMap = new Map<number, boolean>();
    currentUserInfo?.teamIds?.forEach((teamId) => {
      if (!teamsById[teamId]) {
        return;
      }
      teamIdMap.set(teamId, false);
    });
    teamAndDepartmentLeaderOf?.teams?.forEach((teamId) => {
      if (!teamsById[teamId]) {
        return;
      }
      teamIdMap.set(teamId, true);
    });

    const teamDropdownItems: JSX.Element[] = [];
    teamIdMap.forEach((isLeader, teamId) => {
      teamDropdownItems.push(
        getDropdownItem({
          label: getTeamName(teamId, teamsById),
          description: isLeader ? "Team Leader" : "Team Member",
          onClick: () => {
            onTeamDropdownClick(teamId, isLeader);
          },
        })
      );
    });

    return teamDropdownItems;
  };

  const getDepartmentDropdownItems = (): JSX.Element[] => {
    if (isDepartmentsHidden) {
      return [];
    }
    const departmentDropdownItems: JSX.Element[] = [];
    teamAndDepartmentLeaderOf?.departments?.forEach((departmentId) => {
      const department = departmentsById[departmentId];
      if (!department) {
        return;
      }

      departmentDropdownItems.push(
        getDropdownItem({
          label: department.name ?? "",
          description: "Department Leader",
          onClick: () => {
            setShowing({
              teamOrDepartment: "department",
              id: departmentId,
              isLeader: true,
            });
          },
        })
      );
    });
    return departmentDropdownItems;
  };

  const getSelectedDropdownItem = () => {
    if (!showing) {
      return "All To Do";
    }
    if (showing.teamOrDepartment === "team") {
      return getTeamName(showing.id, teamsById);
    }
    if (showing.teamOrDepartment === "department") {
      const department = departmentsById[showing.id];
      return department?.name ?? "";
    }
  };

  const getDropdown = () => {
    const dropdownItems = [
      getDropdownItem({
        label: "All To Do",
        eventKey: "all",
        onClick: () => {
          setShowing(null);
        },
      }),
      ...getTeamDropdownItems(),
      ...getDepartmentDropdownItems(),
    ];

    const dropdownItemsWithDividers: JSX.Element[] = [];
    // in between each dropdown item, add a divider except for the last item
    dropdownItems.forEach((item, index) => {
      dropdownItemsWithDividers.push(item);
      if (index !== dropdownItems.length - 1) {
        dropdownItemsWithDividers.push(<Dropdown.Divider />);
      }
    });

    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {getSelectedDropdownItem()}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          {dropdownItemsWithDividers}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getViewProfileButton = () => {
    if (showing?.teamOrDepartment === "team") {
      return (
        <Button
          variant="secondary-blue"
          onClick={() => {
            navigate(`/TeamGuide/${showing.id}`);
          }}
          className="border-0"
        >
          View Profile <FontAwesomeIcon icon="arrow-right" />
        </Button>
      );
    }
    return null;
  };

  const getEmptyState = () => {
    if (getNotificationsStatus === "loading") {
      return <Loading />;
    }

    return (
      <div
        className="empty-card column-gap-20px"
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <div className="medium-square-icon sapphire-10">
          <FontAwesomeIcon icon={["fas", "stars"]} />
        </div>

        <span>No action needed right now</span>
        <div className="empty-card-text-container">
          <p
            style={{
              marginTop: "-20px",
            }}
          >
            We'll let you know if something comes up
          </p>
        </div>
      </div>
    );
  };

  const getAllRemindMembersActionCard = useCallback((): TActionCard[] => {
    const reminderCards: TActionCard[] = [];
    allSendTeamScanReminderNotifications.forEach(
      ({ notificationId, teamId }) => {
        if (showing?.teamOrDepartment === "team" && showing.id !== teamId) {
          return null;
        }
        const assessmentInfo = teamsMostRecentAssessments[teamId];
        const daysRemaining = getDaysRemaining(assessmentInfo?.endDate);

        reminderCards.push({
          title: `Remind ${getTeamName(
            teamId,
            teamsById
          )} to complete TEAMscan?`,
          description: `${assessmentInfo?.totalCompleted ?? 0} of ${
            assessmentInfo?.totalInvited ?? 0
          } invited members completed the survey. This survey period ends in ${daysRemaining} days. Remind those who haven’t completed?`,
          primaryButton: {
            label: "Remind team members",
            onClick: () => {
              setShowRemindWarningModal({ teamId, notificationId });
            },
          },
          secondaryButton: {
            label: "Dismiss",
            onClick: () => {
              dispatch(dismissNotification(notificationId));
            },
          },
        });
      }
    );
    return reminderCards;
  }, [
    allSendTeamScanReminderNotifications,
    dispatch,
    teamsById,
    showing,
    teamsMostRecentAssessments,
  ]);

  const getPendingTeamActionCardArray = useCallback((): TActionCard[] => {
    const actionCards: TActionCard[] = [];
    allPendingTeamNotifications.forEach((notification) => {
      const { teamId, notificationId } = notification;
      const teamObj = teamsById[teamId];
      if (showing?.teamOrDepartment === "team" && showing.id !== teamId) {
        return null;
      }

      if (!teamObj) {
        return;
      }
      const isDismissed = dismissedNotificationIds.includes(notificationId);
      if (isDismissed) {
        return;
      }
      actionCards.push({
        title: `You’ve been invited to join ${getTeamName(teamId, teamsById)}`,
        description:
          "Once you join a team, you’ll be able to take the TEAMscan survey for this team.",
        primaryButton: {
          label: "Join team",
          onClick: () => {
            dispatch(acceptInvitation({ teamId, notificationId }));
          },
        },
        secondaryButton: {
          label: "Decline",
          onClick: () => {
            dispatch(declineInvitation({ teamId, notificationId }));
          },
        },
      });
    });

    return actionCards;
  }, [
    allPendingTeamNotifications,
    dismissedNotificationIds,
    teamsById,
    dispatch,
    showing,
  ]);

  const getInvitationActionCardArray = useCallback((): TActionCard[] => {
    const actionCards: TActionCard[] = [];
    allPendingAssessmentNotifications.forEach((notification) => {
      const { teamId, notificationId, eventId } = notification;
      const teamObj = teamsById[teamId];
      if (showing?.teamOrDepartment === "team" && showing.id !== teamId) {
        return;
      }
      if (!teamObj) {
        return;
      }
      const isDismissed = dismissedNotificationIds.includes(notificationId);
      if (isDismissed) {
        return;
      }
      actionCards.push({
        title: `Complete TEAMscan for ${getTeamName(teamId, teamsById)}`,
        description: `You’ve been invited to complete the TEAMscan survey for ${getTeamName(
          teamId,
          teamsById
        )}. Take the TEAMscan survey now?`,
        primaryButton: {
          label: "Begin survey",
          onClick: () => {
            navigate(`/survey/team360?eventId=${eventId}&teamId=${teamId}`);
          },
        },
        secondaryButton: {
          label: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });

    return actionCards;
  }, [
    allPendingAssessmentNotifications,
    dismissedNotificationIds,
    teamsById,
    dispatch,
    navigate,
    showing,
  ]);

  const getUnreadAssessmentActionCardArray = useCallback((): TActionCard[] => {
    const actionCards: TActionCard[] = [];
    allAssessmentResultsNotifications?.forEach((notification) => {
      const { teamId, notificationId } = notification;
      if (showing?.teamOrDepartment === "team" && showing.id !== teamId) {
        return;
      }

      actionCards.push({
        title: `You have TEAMscan results for ${getTeamName(
          teamId,
          teamsById
        )}`,
        description: (
          <p className="action-card-description">
            View results and insights from the TEAMscan survey and see how you
            can keep improving teamwork.
          </p>
        ),
        primaryButton: {
          label: "View results",
          onClick: () => {
            navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
            dispatch(dismissNotification(notificationId));
          },
        },
        secondaryButton: {
          label: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });
    return actionCards;
  }, [
    allAssessmentResultsNotifications,
    teamsById,
    dispatch,
    navigate,
    showing,
  ]);

  const getCreateTeamActionCard = useCallback((): TActionCard[] => {
    const actionCard: TActionCard[] = [];
    if (!canUserCreateTeams || showing) return actionCard; // if user can't create teams, don't show this actionCard
    allTeamCreationNotifications.slice(0, 1).forEach((notification) => {
      actionCard.push({
        title: "Create Your First Team",
        description:
          "You’ve been invited to be a team leader. Create your team to invite members and launch the teamwork survey.",
        primaryButton: {
          label: "Create team",
          onClick: () => {
            dispatch(openCreateTeamModal());
          },
        },
        secondaryButton: {
          label: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notification.notificationId));
          },
        },
      });
    });
    return actionCard;
  }, [allTeamCreationNotifications, dispatch, canUserCreateTeams, showing]);

  const getInviteNewMembersActionCard = useCallback((): TActionCard[] => {
    let filteredMemberInvitations = [...allAddTeamMemberNotifications];
    // if the user cannot invite new users we should hide any notifications that don't have a teamId
    if (!canUserInviteUsers) {
      filteredMemberInvitations = filteredMemberInvitations.filter(
        (notification) => !!notification.teamId
      );
    }

    const actionCard: TActionCard[] = [];
    filteredMemberInvitations.forEach(({ notificationId, teamId }) => {
      if (showing?.teamOrDepartment === "team" && showing.id !== teamId) {
        return;
      }

      actionCard.push({
        title: `Add Team Members to ${
          teamId ? getTeamName(teamId, teamsById) : "your organization"
        }`,
        description:
          "You’ve successfully created your team, but haven’t added members yet. Would you like to do this now?",
        primaryButton: {
          label: "Invite",
          onClick: () => {
            setShowInviteMemberModal(teamId ?? true);
          },
        },
        secondaryButton: {
          label: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });
    return actionCard;
  }, [
    dispatch,
    allAddTeamMemberNotifications,
    canUserInviteUsers,
    teamsById,
    showing,
  ]);

  const getLaunchTeamScanActionCard = useCallback((): TActionCard[] => {
    const actionCard: TActionCard[] = [];
    allLaunchTeamScanNotifications.forEach((notification) => {
      const { notificationId, teamId } = notification;
      if (showing?.teamOrDepartment === "team" && showing.id !== teamId) {
        return;
      }
      actionCard.push({
        title: `Measure Teamwork on ${getTeamName(teamId ?? 0, teamsById)}`,
        description:
          "Launch the TEAMscan survey to learn about teamwork on your team.",
        primaryButton: {
          label: "Launch TEAMscan",
          onClick: () => {
            if (teamId) {
              dispatch(showScheduleAssessmentModalForTeamId(teamId));
            } else {
              dispatch(showScheduleAssessmentModal());
            }
          },
        },
        secondaryButton: {
          label: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });

    return actionCard;
  }, [allLaunchTeamScanNotifications, dispatch, teamsById, showing]);

  const getNewDirectReportBanner = useCallback((): TActionCard[] => {
    const banner: TActionCard[] = [];
    allNewDirectReportNotifications.forEach((notification) => {
      const { notificationId, subjectUserAccountId } = notification;
      const userInfo = subjectUserAccountId && usersById[subjectUserAccountId];
      if (!userInfo) {
        return;
      }
      const { firstName, lastName } = userInfo;
      banner.push({
        title: `You have a new direct report, ${firstName} ${lastName}.`,
        description: (
          <p>
            Review {firstName}
            {"'"}s start date and candidate profile, including any shared
            reports, resume and other attachments.
          </p>
        ),
        primaryButton: {
          label: "View Details",
          onClick: () => {
            dispatch(
              setIsNewDirectReportModalOpen({
                userAccountId: Number(subjectUserAccountId),
              })
            );
            dispatch(dismissNotification(notificationId));
          },
        },
        secondaryButton: {
          label: "Dismiss",
          onClick: () => {
            dispatch(dismissNotification(notificationId));
          },
        },
      });
    });

    return banner;
  }, [allNewDirectReportNotifications, dispatch, usersById]);

  const getPrimaryActionCard = ({
    title,
    description,
    primaryButton,
    secondaryButton,
    daysLeft,
  }: TActionCard) => (
    <Card className="action-card">
      <div className="column-gap-4px">
        <p className="action-card-title">{title}</p>
        {typeof description === "string" ? (
          <p className="action-card-description">{description}</p>
        ) : (
          description
        )}
      </div>
      {primaryButton || secondaryButton || daysLeft ? (
        <div className="action-buttons mt-auto">
          <div className="row-gap-8px">
            {primaryButton ? (
              <Button
                variant="primary"
                onClick={primaryButton.onClick}
                className="border-0"
              >
                {primaryButton.label}
              </Button>
            ) : null}
            {secondaryButton ? (
              <Button
                variant="secondary-blue"
                onClick={secondaryButton.onClick}
                className="border-0"
              >
                {secondaryButton.label}
              </Button>
            ) : null}
          </div>
          {daysLeft ? (
            <div className="ms-auto label-tag yellow">
              <p className="action-card-days-left">
                {daysLeft} day{daysLeft === 1 ? "" : "s"} left
              </p>
            </div>
          ) : null}
        </div>
      ) : null}
    </Card>
  );

  const getBody = () => {
    if (showing?.teamOrDepartment === "department") {
      return getEmptyState();
    }

    const allActionCards = [
      ...getPendingTeamActionCardArray(),
      ...getInvitationActionCardArray(),
      ...getUnreadAssessmentActionCardArray(),
      ...getCreateTeamActionCard(),
      ...getInviteNewMembersActionCard(),
      ...getLaunchTeamScanActionCard(),
      ...getAllRemindMembersActionCard(),
      ...getNewDirectReportBanner(),
    ];

    if (allActionCards.length) {
      return (
        <CardContainer
          cards={allActionCards.map((actionCard) =>
            getPrimaryActionCard(actionCard)
          )}
        />
      );
    }
    return getEmptyState();
  };

  return (
    <>
      <InviteUsersModal
        showing={!!showInviteMemberModal}
        hideModal={() => {
          setShowInviteMemberModal(false);
        }}
        teamId={
          typeof showInviteMemberModal === "number"
            ? showInviteMemberModal
            : undefined
        }
        onInviteSuccess={() => setShowInviteMemberModal(false)}
      />
      <WarningModal
        modalTitle="Remind Team Members"
        warningTitle="Remind all team members who have not completed the TEAMscan?"
        warningMessage="Send out an email reminder to an team members who have not completed the TEAMscan survey for this instance."
        isOpen={!!showRemindWarningModal}
        onConfirmClick={async () => {
          if (!showRemindWarningModal) return;
          await dispatch(
            sendReminder({
              reminderType: "remindUserToTakeAssessment",
              teamId: showRemindWarningModal.teamId,
            })
          );

          if (showRemindWarningModal.notificationId) {
            dispatch(
              dismissNotification(showRemindWarningModal.notificationId)
            );
          }
          setShowRemindWarningModal(null);
        }}
        hideModal={() => {
          setShowRemindWarningModal(null);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      <WarningModal
        modalTitle="Remind Members to Take Personality Assessment"
        warningTitle="Remind all team members who have not completed their personality assessment?"
        warningMessage="Send out an email reminder to an team members who have not completed the personality survey for this team."
        isOpen={!!showRemindTeamToTakeEPP}
        onConfirmClick={async () => {
          if (!showRemindTeamToTakeEPP) return;
          dispatch(
            remindMembersFromTeamToTakePersonalityTest({
              teamIds: [showRemindTeamToTakeEPP.teamId],
            })
          );
          setShowRemindTeamToTakeEPP(null);
        }}
        hideModal={() => {
          setShowRemindTeamToTakeEPP(null);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      <EditSurveyModal
        onHide={() => {
          setExtendExpirationWarningModal(false);
        }}
        show={!!extendExpirationWarningModal}
        editingAssessmentId={
          extendExpirationWarningModal
            ? extendExpirationWarningModal
            : undefined
        }
      />

      <Card className="dashboard-onboard-card todo-card-v2">
        <div className="dashboard-onboard-card-header">
          <h2>To Do </h2>
          <Button
            onClick={onSeeAll}
            variant="secondary-blue"
            className="border-0"
          >
            See all
          </Button>
        </div>
        <div className="row-gap-16px">
          {getDropdown()} {getViewProfileButton()}
        </div>
        {getBody()}
      </Card>
    </>
  );
}
