import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";

interface Props {
  readonly hideModal: () => void;
  readonly onSave: () => void;
  readonly show: boolean;
}

export default function ConversationHistoryModal({
  hideModal,
  onSave,
  show,
}: Props) {
  return (
    <Modal show={show} onHide={hideModal} className="simple-modal" size="lg">
      <div className="modal-title-row">
        <h2>Conversation History</h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none" }}
          xIcon
        />
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <p>
          <strong>
            Are you sure you want to turn off this setting for your entire
            organization?
          </strong>
        </p>
        <p
          style={{
            marginTop: "8px",
          }}
        >
          This will permanently delete all conversation history for everyone in
          your organization. <br></br>Note: Each individual can turn off
          conversation history in their user settings.
        </p>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Button onClick={hideModal} variant="secondary-blue">
          Cancel
        </Button>
        <Button onClick={onSave}>Confirm Changes</Button>
      </div>
    </Modal>
  );
}
