// CardSwiper.tsx
import React, { useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface Props {
  cards: JSX.Element[];
}

const CardContainer: React.FC<Props> = ({ cards }) => {
  const [leftBlur, setLeftBlur] = useState(false);
  const [rightBlur, setRightBlur] = useState(false);

  const onSetBlur = (swiper: SwiperClass) => {
    setLeftBlur(!swiper.isBeginning);
    setRightBlur(!swiper.isEnd);
  };

  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={20}
      navigation
      scrollbar={{
        draggable: true,
      }}
      mousewheel={true}
      modules={[Mousewheel, Navigation]}
      className="swiper-container"
      onSnapGridLengthChange={onSetBlur}
      onNavigationNext={onSetBlur}
      onNavigationPrev={onSetBlur}
      onScroll={onSetBlur}
    >
      {leftBlur ? <div className="gradient-blur left" /> : null}
      {cards.slice(0, 20).map((card, index) => (
        <SwiperSlide
          key={index}
          style={{
            width: "fit-content",
          }}
        >
          {card}
        </SwiperSlide>
      ))}
      {rightBlur ? <div className="gradient-blur right" /> : null}
    </Swiper>
  );
};
export default CardContainer;
