/**
 *
 * GlobalNavBar
 *
 */
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import DevelopLogo from "resources/icons/Develop_Logo.svg";
import CriteriaLogoIcon from "resources/icons/Criteria_Icon_Logo.svg";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectGetUserStatus,
  selectCurrentUserAccountId,
  selectCompanyInfo,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectIsCurrentUserAdmin,
  selectConfigCatFlag,
} from "app/containers/Global/slice";
import AvatarCircle from "app/components/AvatarCircle";
import Select, { SingleValue } from "react-select";
import { SearchHistoryItem, SearchOption } from "./types";
import { getSelectProps, logUserOut } from "utils/helperFunctions";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import { GuideType } from "app/components/Modules/types";
import NotificationBellWithNumber from "app/storybookComponents/NotificationBellWithNumber";
import NotificationCard from "app/components/Notifications/NotificationCard";
import {
  selectAllAddTeamMemberNotifications,
  selectAllAssessmentResultsNotifications,
  selectAllLaunchTeamScanNotifications,
  selectAllNewDirectReportNotifications,
  selectAllPendingAssessmentsNotifications,
  selectAllSendTeamScanReminderNotifications,
  selectAllTeamCreationNotifications,
  selectAllTeamInvitations,
  selectDismissedNotificationIds,
} from "app/components/Notifications/slice";
import {
  IS_AU_INSTANCE,
  REACT_APP_HIRESELECT_URL,
} from "utils/environmentVariables";
import useWidthBasedVisibility from "utils/hooks/useWidthBasedVisibility";
import {
  selectCompanySettings,
  selectDepartments,
  selectIsDepartmentsHidden,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import {
  selectIsNotificationDropdownOpen,
  setIsNotificationDropdownOpen,
} from "./slice";
import ViewResultsReadyModal from "app/components/Notifications/ViewResultsReadyModal";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import InviteUsersModal from "../Modals/InviteUsersModal";
import useTrackActiveTimeInComponent from "utils/hooks/useTrackActiveTimeInComponent";
import HoverNavDropdown from "./HoverNavDropdown";
import { getMemberOption, getUserOption } from "./helpers";

export default function GlobalNavBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useTrackActiveTimeInComponent();

  // ----------------------------- App Selectors ---------------------------------------- //
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const companyGuide = useAppSelector(selectCompanyGuide);
  const getUserStatus = useAppSelector(selectGetUserStatus);
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const allAssessmentResultsNotifications = useAppSelector(
    selectAllAssessmentResultsNotifications
  );
  const allTeamCreationNotifications = useAppSelector(
    selectAllTeamCreationNotifications
  );
  const allAddTeamMemberNotifications = useAppSelector(
    selectAllAddTeamMemberNotifications
  );
  const allSendTeamScanReminderNotifications = useAppSelector(
    selectAllSendTeamScanReminderNotifications
  );
  const allLaunchTeamScanNotifications = useAppSelector(
    selectAllLaunchTeamScanNotifications
  );
  const allNewDirectReportNotifications = useAppSelector(
    selectAllNewDirectReportNotifications
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const isNotificationDropdownOpen = useAppSelector(
    selectIsNotificationDropdownOpen
  );
  const dismissedNotificationIds = useAppSelector(
    selectDismissedNotificationIds
  );
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const canUserInviteUsers = useAppSelector(selectUserIsAbleToInviteUsers);
  const companySettings = useAppSelector(selectCompanySettings);
  const departments = useAppSelector(selectDepartments);
  const coachBoConversationHistoryFeatureFlag = useAppSelector(
    selectConfigCatFlag("develop_coachboconversationhistory")
  );

  // ----------------------------- use State ---------------------------------------- //
  const [inputValue, setInputValue] = useState("");
  const [forceShowSearchBar, setForceShowSearchBar] = useState(false);
  const isHamburgerHidden = useWidthBasedVisibility(768); // isHamburgerHidden
  const isGreaterThan620 = useWidthBasedVisibility(620);
  const isGreaterThan1200 = useWidthBasedVisibility(1200);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNotificationPulsing, setIsNotificationPulsing] = useState(false);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState<
    number | boolean
  >(false);

  // ----------------------------- refs ---------------------------------------- //
  const selectRef = useRef<any>(null);

  // ----------------------------- memo constants ---------------------------------------- //
  // We shall use this information to determine if the button to pre-hire should be shown or not.
  const userInformation = useMemo(
    () => (userAccountId ? usersById[userAccountId] : undefined),
    [userAccountId, usersById]
  );

  const getNotificationCount = useCallback(() => {
    const teamInvitations: {
      [teamId: number]: true;
    } = {};
    const assessmentInvitations: {
      [assessmentId: number]: true;
    } = {};
    allPendingAssessmentNotifications?.forEach((notification) => {
      if (
        notification.teamId &&
        !dismissedNotificationIds.includes(notification.notificationId)
      ) {
        assessmentInvitations[notification.teamId] = true;
      }
    });
    allPendingTeamNotifications?.forEach((notification) => {
      if (
        notification.teamId &&
        !dismissedNotificationIds.includes(notification.notificationId)
      ) {
        teamInvitations[notification.teamId] = true;
      }
    });
    const allCreateTeamNotifications = canUserCreateTeams
      ? allTeamCreationNotifications.length
      : 0;
    let filteredInviteNotificationsLength =
      allAddTeamMemberNotifications.length;
    if (!canUserInviteUsers) {
      filteredInviteNotificationsLength = allAddTeamMemberNotifications.filter(
        (notification) => !!notification.teamId
      ).length;
    }

    return (
      Object.keys(teamInvitations).length +
      Object.keys(assessmentInvitations).length +
      allAssessmentResultsNotifications.length +
      allCreateTeamNotifications +
      filteredInviteNotificationsLength +
      allSendTeamScanReminderNotifications.length +
      allLaunchTeamScanNotifications.length +
      allNewDirectReportNotifications.length
    );
  }, [
    allPendingAssessmentNotifications,
    allPendingTeamNotifications,
    dismissedNotificationIds,
    allAssessmentResultsNotifications,
    allTeamCreationNotifications,
    allAddTeamMemberNotifications,
    allSendTeamScanReminderNotifications,
    allLaunchTeamScanNotifications,
    allNewDirectReportNotifications,
    canUserCreateTeams,
    canUserInviteUsers,
  ]);

  useEffect(() => {
    const newCount = getNotificationCount();
    // if the count changed and the count is greater than 0 then we should set the notification to pulsate.
    setNotificationCount((prevCount) => {
      if (newCount !== prevCount && newCount > 0) {
        setIsNotificationPulsing(true);
      }
      return newCount;
    });
  }, [getNotificationCount]);

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "logout": {
        return logUserOut();
      }
      case "criteria-platform": {
        window.location.href = `${REACT_APP_HIRESELECT_URL}`;
        return;
      }
      case "userGuide": {
        return navigate("/UserGuide");
      }
      case "userSettings": {
        return navigate("/UserSettings");
      }
      case "howToGuide": {
        return window.open(
          IS_AU_INSTANCE
            ? "https://criteriacorp.helpdocs.io/category/1zfqwhxqcs-develop-by-criteria"
            : "https://criteria.helpdocs.io/category/jdd7ethxiq-develop-by-criteria",
          "_blank"
        );
      }
      case "contactSupport": {
        return window.open(
          `mailto:${
            IS_AU_INSTANCE
              ? "au.customersupport@criteriacorp.com"
              : "techsupport@criteriacorp.com"
          }`
        );
      }
    }
  };

  const getNoSearchOptions = () => {
    const searchOptions = [
      { label: "Browse all teams", value: "all teams", isSearchOption: true },
      {
        label: "Browse all people",
        value: "all users",
        isSearchOption: true,
      },
    ];

    if (!isDepartmentsHidden) {
      searchOptions.push({
        label: "Browse all departments",
        value: "all departments",
        isSearchOption: true,
      });
    }

    return [...searchOptions, ...getHistoryOptions()];
  };

  const getAllSearchOptions = (): SearchOption[] => {
    if (!inputValue) {
      return getNoSearchOptions();
    }

    const searchOptions: SearchOption[] = [];
    // We need to only return back 5 items
    if (
      companyInfo?.companyName?.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      const companyOption = getCompanyOption();
      if (companyOption) searchOptions.push(companyOption);
    }

    Object.values(teamByTeamId).forEach((team) => {
      if (team.teamName.toLowerCase().includes(inputValue.toLowerCase())) {
        const teamOption = getTeamOption(team.teamId);
        if (teamOption) searchOptions.push(teamOption);
      }
    });

    Object.values(usersById).forEach((user) => {
      if (
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      ) {
        const userOption = getUserOption(usersById, user.userAccountId);
        if (userOption) {
          searchOptions.push(userOption);
        }
      }
    });

    if (searchOptions.length > 5) {
      searchOptions.splice(5);
      searchOptions.push({
        label: `Search for "${inputValue}"`,
        value: inputValue,
        isSearchOption: true,
        onClick: () => navigate(`/Search/People?searchInput=${inputValue}`),
      });
    }

    return searchOptions;
  };

  const getTeamOption = (teamId: number) => {
    if (!teamByTeamId[teamId]) return null;

    const team = teamByTeamId[teamId];
    return {
      label: team.teamName,
      value: String(team.teamId),
      avatarCircle: (
        <AvatarCircle
          name={team.teamName}
          profilePicture={team.profilePicture}
          avatarColor={team.avatarColor}
          size="small"
        />
      ),
      guideType: "team" as GuideType,
    };
  };

  const getCompanyOption = () => {
    if (!companyInfo) return null;
    return {
      label: companyInfo.companyName,
      value: String(companyInfo.companyAccountId),
      avatarCircle: (
        <AvatarCircle
          name={companyInfo.companyName}
          size={"small"}
          profilePicture={companyGuide?.profilePicture?.picture}
        />
      ),
      guideType: "company" as GuideType,
    };
  };

  const getHistoryOptions = (): SearchOption[] => {
    const searchHistory = localStorage.getItem("searchHistory");
    if (!searchHistory) {
      return [];
    }
    const historyArray = JSON.parse(searchHistory);
    const returnArr: SearchOption[] = [];
    historyArray.forEach((historyItem: SearchHistoryItem) => {
      const option = getHistoryOption(
        historyItem.label,
        historyItem.value,
        historyItem.guideType
      );
      if (option) returnArr.push(option);
    });
    return returnArr;
  };

  const getHistoryOption = (
    label: string,
    value: string = "",
    guideType?: GuideType
  ) => {
    switch (guideType) {
      case "company": {
        return getCompanyOption();
      }
      case "team": {
        return getTeamOption(Number(value));
      }
      case "user": {
        return getUserOption(usersById, Number(value));
      }
      default: {
        return {
          label,
          value,
        };
      }
    }
  };

  const onGetMyTeams = () => {
    if (!userAccountId) return [];
    const myTeams: ReactElement[] = [];
    usersById?.[userAccountId]?.teamIds?.forEach((teamId) => {
      if (teamByTeamId[teamId])
        myTeams.push(
          <NavDropdown.Item
            as={NavLink}
            to={`/TeamGuide/${teamId}`}
            key={teamId}
          >
            <AvatarCircle
              size="small"
              name={teamByTeamId[teamId].teamName}
              profilePicture={teamByTeamId[teamId].profilePicture}
              avatarColor={teamByTeamId[teamId].avatarColor}
            />
            {teamByTeamId[teamId].teamName}
          </NavDropdown.Item>
        );
    });

    if (myTeams.length === 0) return null;
    return (
      <>
        <span>My Teams' Profiles</span>
        {myTeams}
      </>
    );
  };

  const onGetMyDepartments = () => {
    if (
      !userAccountId ||
      isDepartmentsHidden ||
      !coachBoConversationHistoryFeatureFlag // tying this feature flag to the updated department guide
    ) {
      return [];
    }

    const myDepartments: ReactElement[] = [];
    teamAndDepartmentLeaderOf?.departments?.forEach((departmentId) => {
      const department = departments[departmentId];
      if (!department) {
        return;
      }
      myDepartments.push(
        <NavDropdown.Item
          as={NavLink}
          to={`/DepartmentGuide/${departmentId}`}
          key={departmentId}
        >
          <AvatarCircle size="small" name={department.name} />
          {department.name}
        </NavDropdown.Item>
      );
    });

    if (myDepartments.length === 0) return null;
    return (
      <>
        <span>My Departments' Profiles</span>
        {myDepartments}
      </>
    );
  };

  const onSelectChange = (newValue: SingleValue<SearchOption>) => {
    if (newValue) {
      updateHistory(newValue);
    }
    if (newValue?.value === "all teams") return navigate("/Search/Teams");
    if (newValue?.value === "all users") return navigate("/Search/People");
    if (newValue?.value === "all departments")
      return navigate("/Search/Departments");
    if (newValue?.guideType === "company") {
      return navigate(`/OrganizationGuide`);
    }
    if (newValue?.guideType === "team") {
      return navigate(`/TeamGuide/${newValue?.value}`);
    }
    if (newValue?.guideType === "user") {
      return navigate(`/UserGuide/${newValue?.value}`);
    }
    newValue?.onClick?.();
    // If they made it all the way down here then they clicked on "Search for <search term>"
  };

  // When a new value is selected, first we check if that value exist inside of our local storage history.
  // If it does not exist then we add it to the local storage history.
  // If it does exist then we move it to the top of the local storage history.
  // If the local storage history is greater than 5 then we remove the last item in the local storage history.
  // If the local storage history is less than 5 then we add the new value to the local storage history.
  const updateHistory = (searchOption: SearchOption) => {
    const { label, value, guideType, isSearchOption } = searchOption;
    if (isSearchOption) return;
    const history = localStorage.getItem("searchHistory");
    if (!history) {
      localStorage.setItem(
        "searchHistory",
        JSON.stringify([{ label, value, guideType }])
      );
      return;
    }

    const historyArray = JSON.parse(history);
    const index = historyArray.findIndex(
      (item: SearchHistoryItem) =>
        item.label === label &&
        item.value === value &&
        item.guideType === guideType
    );

    if (index > -1) {
      historyArray.splice(index, 1);
    }

    historyArray.unshift({ label, value, guideType });

    if (historyArray.length > 5) {
      historyArray.pop();
    }

    localStorage.setItem("searchHistory", JSON.stringify(historyArray));
  };

  const getSearchBar = () => (
    <Select<SearchOption>
      placeholder="Search teams and people..."
      options={options}
      components={components}
      value={null}
      formatOptionLabel={getMemberOption}
      styles={selectStyles}
      onChange={onSelectChange}
      filterOption={() => true}
      inputValue={inputValue}
      onInputChange={setInputValue}
      isClearable
      ref={selectRef}
    />
  );

  const getSearchElement = () => {
    // if greater than 1200 then show the search bar
    if (isGreaterThan1200) return getSearchBar();
    if (forceShowSearchBar) {
      return (
        <div className="row-gap-8px align-items-center">
          {getSearchBar()}
          <div role="button" onClick={() => setForceShowSearchBar(false)}>
            <FontAwesomeIcon icon="times" />
          </div>
        </div>
      );
    }

    return (
      <div role="button" onClick={() => setForceShowSearchBar(true)}>
        <FontAwesomeIcon icon="search" />
      </div>
    );
  };

  const getGuidesDropdown = () => (
    <HoverNavDropdown
      title="Profiles"
      id="collasible-nav-dropdown"
      className="guide-dropdown"
    >
      <span>My Profile</span>
      <NavDropdown.Item as={NavLink} to="/UserGuide">
        <AvatarCircle size="small" userAccountId={userAccountId || undefined} />
        {user?.firstName} {user?.lastName}
      </NavDropdown.Item>
      {onGetMyTeams()}
      {onGetMyDepartments()}
      <span>Organization</span>
      <NavDropdown.Item as={NavLink} to="/OrganizationGuide">
        {companyOptionInfo?.avatarCircle}
        {companyOptionInfo?.label}
      </NavDropdown.Item>
    </HoverNavDropdown>
  );

  const getButtonToPreHire = () => {
    // if the user does not have access to the pre-hire then we should return null.
    if (!userInformation?.hasAccessToPreHire) return null;
    // if the searchbar is showing and the greater than 1200 is false then we should return null.
    if (forceShowSearchBar && !isGreaterThan1200) return null;
    return (
      <>
        <div className="nav-bar-vertical-line" />
        <a href={`${REACT_APP_HIRESELECT_URL}`} className="nav-link">
          Hire
          <FontAwesomeIcon icon="external-link" className="ms-2" size="xs" />
        </a>
      </>
    );
  };

  const getLibraryNavLink = () => {
    if (!companySettings?.aiFeaturesEnabled) {
      return (
        <Nav.Link as={NavLink} to="/SkillsGuide" id="navbar-skills-guide-link">
          Library
        </Nav.Link>
      );
    }
    const basePath = window.location.pathname.split("/")[1];
    const isActive =
      basePath === "SkillsGuide" || basePath === "CoachBotPrompts";
    return (
      <HoverNavDropdown
        title="Library"
        id="collasible-nav-dropdown"
        className="library-nav-dropdown guide-dropdown"
        active={isActive}
      >
        <NavDropdown.Item to={"/CoachBoPrompts"} as={NavLink}>
          Coach Bo Chats Prompts
        </NavDropdown.Item>
        <NavDropdown.Item to={"/SkillsGuide"} as={NavLink}>
          Teamwork Skills
        </NavDropdown.Item>
      </HoverNavDropdown>
    );
  };

  const getUserNavigationCollapse = () => (
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        {isAdmin ? (
          <Nav.Link
            as={NavLink}
            to="/AdminConsole/Dashboard"
            id="navbar-admin-console-link"
          >
            Admin Console
          </Nav.Link>
        ) : null}
        <Nav.Link as={NavLink} to="/home" id="navbar-home-link">
          Home
        </Nav.Link>
        {getGuidesDropdown()}
        {getLibraryNavLink()}
        {/* Only when the hamburger is hidden is when we show the nav items inside of the actual navbar */}
        {!isHamburgerHidden ? (
          <>
            <Nav.Link as={NavLink} to="/UserGuide" id="navbar-user-guide-link">
              My User Guide
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/UserSettings"
              id="navbar-user-settings-link"
            >
              User Settings
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/"
              active={false}
              onClick={() => {
                logUserOut();
              }}
              className="not-active"
              id="navbar-logout-link"
            >
              Log Out
            </Nav.Link>
          </>
        ) : null}
        {getButtonToPreHire()}
      </Nav>
    </Navbar.Collapse>
  );

  const getHelpDropdown = () => (
    <NavDropdown
      title={
        <FontAwesomeIcon
          icon={["far", "question-circle"]}
          className="help-icon"
          style={{ fontSize: "24px" }}
        />
      }
      onSelect={onDropdownSelect}
      align="end"
    >
      <Dropdown.Item eventKey="howToGuide">How-To Guides</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="contactSupport">Contact Support</Dropdown.Item>
    </NavDropdown>
  );

  const getAvatarDropdown = () => {
    if (!userAccountId) return null;
    if (!isHamburgerHidden) return null;
    return (
      <NavDropdown
        title={
          user ? (
            <AvatarCircle
              userAccountId={userAccountId || undefined}
              profilePicture={user.profilePicture}
              avatarColor={user.avatarColor}
            />
          ) : null
        }
        id="user-settings"
        onSelect={onDropdownSelect}
        align="end"
      >
        <Dropdown.Item eventKey="userGuide">My User Guide</Dropdown.Item>
        <Dropdown.Item eventKey="userSettings">User Settings</Dropdown.Item>
        {userInformation?.hasAccessToPreHire ? (
          <Dropdown.Item eventKey="criteria-platform">
            Hire by Criteria
            <FontAwesomeIcon icon="external-link" className="ms-2" />
          </Dropdown.Item>
        ) : null}
        <Dropdown.Item eventKey="logout">Logout</Dropdown.Item>
      </NavDropdown>
    );
  };

  const getRightGroupedElements = () => (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
      className="row-gap-20px"
    >
      {getSearchElement()}
      <NavDropdown
        title={
          <NotificationBellWithNumber
            hasPendingNotifications={!!notificationCount}
            numberCount={notificationCount}
            color="#202D63"
            className={isNotificationPulsing ? "pulsate" : undefined}
          />
        }
        id="notification-dropdown"
        show={isNotificationDropdownOpen}
        onToggle={() => {
          dispatch(setIsNotificationDropdownOpen(!isNotificationDropdownOpen));
          setIsNotificationPulsing(false);
        }}
      >
        <NotificationCard
          onInviteUsersToTeam={(teamId) => setShowInviteMemberModal(teamId)}
        />
      </NavDropdown>
      {getHelpDropdown()}
      {getAvatarDropdown()}
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="navbar-toggle"
      />
    </div>
  );

  const getUserNavigation = () => {
    if (getUserStatus !== "succeeded") {
      return null;
    }

    if (isHamburgerHidden) {
      return (
        <>
          {getUserNavigationCollapse()}
          {getRightGroupedElements()}
        </>
      );
    }

    // When collapsed we need to reverse the order of the elements
    return (
      <>
        {getRightGroupedElements()}
        {getUserNavigationCollapse()}
      </>
    );
  };

  const getCompanyLogo = () => {
    // if force the search bar to show, the hamburger is showing, and the screen is smaller than 620 then we need to hide the logo
    if (forceShowSearchBar && !isHamburgerHidden && !isGreaterThan620) {
      return <div />;
    }

    return (
      <Navbar.Brand as={NavLink} to="/home">
        <div className="row-gap-8px align-items-center ms-1">
          <div>
            <img
              src={CriteriaLogoIcon}
              alt="Criteria Logo Icon"
              style={{
                height: "25px",
              }}
            />
            <img
              src={DevelopLogo}
              alt="Develop Logo"
              style={{
                width: "87px",
              }}
            />
          </div>
        </div>
      </Navbar.Brand>
    );
  };

  const { selectStyles, components } = getSelectProps("search", {
    fixedWidth: 300,
    controlBgColor: "#eceef9",
  });

  const user = (userAccountId && usersById[userAccountId]) || undefined;
  const options = getAllSearchOptions();
  const companyOptionInfo = getCompanyOption();
  return (
    <>
      <ViewResultsReadyModal />
      <InviteUsersModal
        showing={!!showInviteMemberModal}
        hideModal={() => {
          setShowInviteMemberModal(false);
        }}
        teamId={
          typeof showInviteMemberModal === "number"
            ? showInviteMemberModal
            : undefined
        }
        onInviteSuccess={() => setShowInviteMemberModal(false)}
      />
      <Navbar
        collapseOnSelect
        expand={isHamburgerHidden}
        className="global-nav-bar"
        variant="dark"
      >
        {getCompanyLogo()}
        {getUserNavigation()}
      </Navbar>
    </>
  );
}
