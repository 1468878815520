import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import pendingTestImg from "resources/images/pending-test-img.png";
import { REACT_APP_ODA_URL } from "utils/environmentVariables";

interface Props {
  percentComplete?: number;
  eventId: string | number;
  linkResultsAccess?: boolean | 0 | 1;
  onLinkPreviousResults: () => void;
}

export default function PendingTest({
  percentComplete,
  eventId,
  onLinkPreviousResults,
}: Readonly<Props>) {
  return (
    <Card className="pending-test-card">
      <div>
        <img src={pendingTestImg} alt="pending test" height="159" />
      </div>
      <div className="column-gap-12px">
        <div className="row-gap-8px align-items-center">
          <div className="pending-tests-progress-circle" />
          <h2>
            {percentComplete
              ? `You're ${percentComplete}% done with your personality test`
              : `You’re almost done! Complete your profile`}
          </h2>
        </div>
        <p>
          Once you complete your profile by taking the personality test, you’ll
          have access to:
        </p>
        <div>
          <p className="pending-tests-text">
            <FontAwesomeIcon icon="arrow-right" />
            Your personalized Workplace Insights report
          </p>
          <p className="pending-tests-text">
            <FontAwesomeIcon icon="arrow-right" />
            Collaboration Guides with tips for working with specific teammates
          </p>
          <p className="pending-tests-text">
            <FontAwesomeIcon icon="arrow-right" />
            Team Personality Reports to see how you compare with your teammates
          </p>
        </div>
      </div>
      <div className="column-gap-16px">
        <a
          href={`${REACT_APP_ODA_URL}verify/index/${eventId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button style={{ width: "100%" }}>
            {percentComplete
              ? "Resume Personality Test"
              : "Begin Personality Test"}
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </a>
        <Button
          variant="secondary-blue"
          className="white-button"
          onClick={onLinkPreviousResults}
        >
          Link Previous Results
        </Button>
      </div>
    </Card>
  );
}
