export const segmentIdentifyUser = (
  userAccountId: number,
  loginType: string = "password",
  instancePrefix: string = ""
) => {
  // we need the internal property of the token to determine if the user logged in from the intranet
  window.analytics?.identify(
    `${userAccountId}${instancePrefix === "AU_" ? "au" : ""}`,
    {
      timeStamp: new Date(),
      type: loginType,
    }
  );
};

export const trackEvent = (
  eventName: string,
  data:
    | {
        [key: string]: string | number | boolean | null | undefined;
      }
    | undefined = {}
) => {
  window.analytics?.track(`Develop By Criteria - ${eventName}`, {
    isPostHire: true,
    eventName,
    ...data,
  });
};

export const trackPageVisit = (pathName: string) => {
  window.analytics?.page("Develop By Criteria - Page View", {
    isPostHire: true,
    url: window.location.href,
    path: pathName,
  });
};

export const trackDirectReportModalOpened = () => {
  trackEvent("New Direct Report Modal Opened");
};

export const trackUserGuideTabChange = (tabName: string, isSelf: boolean) => {
  trackEvent("User Guide Tab Change", { tabName, isSelf: 0 | 1 });
};

export const trackUserDownloadedAttachment = (
  attachmentType: "Cover Letter" | "Resume"
) => {
  trackEvent("User Job Profile Attachment Downloaded", { attachmentType });
};
