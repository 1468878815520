import PeopleSearchDropdown from "app/components/Dropdowns/PeopleSearchDropdown";
import Button from "app/storybookComponents/Button";
import SimpleModal from "app/components/Modals/SimpleModal";
import { useEffect, useState } from "react";

interface Props {
  show?: boolean;
  onClose: () => void;
  title?: string;
  descriptionText?: string;
  onSave?: (userAccountIds: number[]) => void;
  defaultUserAccountIds?: number[] | null;
}

export default function WhoCanDoSomethingModal({
  show,
  onClose,
  title = "Who can do something",
  descriptionText,
  onSave,
  defaultUserAccountIds,
}: Readonly<Props>) {
  const [userAccountIds, setUserAccountIds] = useState<number[]>([]);

  useEffect(() => {
    if (defaultUserAccountIds) {
      setUserAccountIds(defaultUserAccountIds);
    }
  }, [defaultUserAccountIds]);

  return (
    <SimpleModal show={show} onHide={onClose} title={title}>
      {descriptionText ? <p>{descriptionText}</p> : null}
      <PeopleSearchDropdown
        isMulti
        userAccountIds={userAccountIds}
        setUserAccountIds={setUserAccountIds}
      />
      <div>
        <Button
          onClick={() => {
            onSave?.(userAccountIds);
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </SimpleModal>
  );
}
