import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import CreateTeamForm from "app/storybookComponents/Forms/CreateTeamForm";
import TeamAverageIllustration from "resources/images/illustration-team-average.png";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  createTeam,
  inviteUserByEmail,
  selectCurrentUserAccountId,
  selectInviteUserByEmailStatus,
  selectCreateTeamStatus,
  selectInviteLinkByTeamId,
  selectIsCurrentUserAdmin,
  selectAllCompanyUsersById,
  selectInvalidInvitedStrings,
  resetInvalidInvitedStrings,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  selectCreateTeamModalIsOpen,
  closeCreateTeamModal,
  selectRedirectAfterCreatingTeam,
  selectCreateTeamModalPreSelectedDepartmentId,
  selectCreatedTeamId,
  openEditDepartmentModal,
  selectIsModalComingFromAdminConsole,
} from "app/components/Modals/slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { Team } from "app/containers/Global/types";
import { useNavigate } from "react-router-dom";
import { getAllowedDomains } from "app/containers/AdminConsole/helpers";
import {
  checkNewEmail,
  selectCheckedEmails,
  selectCompanyInviteLink,
  selectCompanySettings,
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import { showScheduleAssessmentModalForTeamId } from "../LaunchAssessmentModal/slice";
import { selectUserIsAbleToInviteUsers } from "app/containers/UserGuide/slice";

export default function CreateTeamModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const isCreateTeamModalOpen = useAppSelector(selectCreateTeamModalIsOpen);
  const preselectedDepartmentId = useAppSelector(
    selectCreateTeamModalPreSelectedDepartmentId
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const redirectUponCreation = useAppSelector(selectRedirectAfterCreatingTeam);
  const createTeamStatus = useAppSelector(selectCreateTeamStatus);
  const createdTeamId = useAppSelector(selectCreatedTeamId);
  const companyInviteLink = useAppSelector(selectCompanyInviteLink);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const inviteUserByEmailStatus = useAppSelector(selectInviteUserByEmailStatus);
  const teamLinksById = useAppSelector(selectInviteLinkByTeamId);
  const companySettings = useAppSelector(selectCompanySettings);
  const departments = useAppSelector(selectDepartments);
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);
  const isModalComingFromAdminConsole = useAppSelector(
    selectIsModalComingFromAdminConsole
  );
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const checkedEmails = useAppSelector(selectCheckedEmails);

  const [createTeamForm, setCreateTeamForm] = useState<{
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
  }>({
    // Step 1
    teamName: "",
    departmentId: 0,
    // Step 2
    teamDescription: "",
    // Step 3
    teamMemberEmails: [],
  });
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [sendEmails, setSendEmails] = useState(true);

  useEffect(() => {
    setCreateTeamForm((prev) => ({
      ...prev,
      departmentId: preselectedDepartmentId ?? 0,
    }));
  }, [preselectedDepartmentId]);

  const hideModal = () => {
    // If we are trying to close the modal and we have created a team then we need to show the success screen
    if (!showSuccessScreen && createdTeamId) {
      setShowSuccessScreen(true);
      return;
    }

    dispatch(closeCreateTeamModal());
    dispatch(resetInvalidInvitedStrings());
    setCreateTeamForm({
      teamName: "",
      departmentId: 0,
      teamDescription: "",
      teamMemberEmails: [],
    });
    setShowSuccessScreen(false);
  };

  const showing = !!isCreateTeamModalOpen;

  const onCreateTeam = async (
    payload: {
      teamName: string;
      departmentId: number;
      teamDescription: string;
      teamMemberEmails: string[];
      teamLeaderEmail?: string;
      sendEmails: boolean;
    },
    userAccountId: number
  ) => {
    const response = (await dispatch(
      createTeam({
        payload,
        userAccountId,
      })
    )) as PayloadAction<{
      response: Team;
      userAccountId: number | string;
    }>;
    const teamId = response?.payload?.response?.teamId;

    if (redirectUponCreation) {
      navigate(`/teamGuide/${teamId}`);
    }
  };

  const getMemberInvitedCountString = (memberLength: number) => {
    if (!memberLength) {
      return null;
    }
    const addedOrInvited = sendEmails ? "invited" : "added";

    return (
      <p
        style={{
          padding: "0 32px",
        }}
      >
        {memberLength} team member{memberLength === 1 ? "" : "s"} have been{" "}
        {addedOrInvited}.
        {sendEmails
          ? " Invited users will receive an email with an invitation to join Develop by Criteria. Once they are logged in, they will be able to view this team."
          : null}
      </p>
    );
  };

  const getSuccessScreen = (currentUserAccountId: number) => {
    const createdTeam = createdTeamId ? allTeamsById[createdTeamId] : null;

    let memberLength = createdTeam?.teamMembers?.length ?? 0;

    if (
      createdTeam?.teamLeadUserAccountIds?.includes(currentUserAccountId) &&
      memberLength
    ) {
      memberLength -= 1;
    }

    // We need to show the number of total members in the team
    return (
      <div>
        <Button
          onClick={() => hideModal()}
          variant={"secondary-blue"}
          style={{
            border: "none",
            width: "auto",
            position: "absolute",
            right: "16px",
            top: "16px",
          }}
          xIcon
        />
        <div className="column-gap-20px">
          <div className="column-gap-12px">
            <p className="mb-0">Done</p>
            <h2>Team Successfully Created</h2>
          </div>
          <div className="column-gap-16px text-align-center align-items-center">
            <div>
              <img src={TeamAverageIllustration} alt="Teams" />
            </div>
            <p
              style={{
                fontSize: "16px",
              }}
            >
              <b>"{createdTeam?.teamName}" has been successfully created</b>
            </p>
            {getMemberInvitedCountString(memberLength)}
          </div>
          <div className="row-gap-8px justify-content-center my-2">
            <Button
              onClick={() => {
                navigate(`/teamGuide/${createdTeamId}`);
                hideModal();
              }}
            >
              Go to team guide
            </Button>
            <Button
              variant="secondary-blue"
              onClick={() => {
                if (!createdTeamId) return;
                dispatch(showScheduleAssessmentModalForTeamId(createdTeamId));
                hideModal();
              }}
            >
              Launch TEAMscan
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getModalContent = () => {
    if (!currentUserAccountId) return null;

    if (showSuccessScreen) {
      return getSuccessScreen(currentUserAccountId);
    }

    return (
      <CreateTeamForm
        form={createTeamForm}
        setForm={setCreateTeamForm}
        onCreateTeam={(payload) => {
          onCreateTeam({ ...payload, sendEmails }, currentUserAccountId);
        }}
        onClose={hideModal}
        isLoading={
          createTeamStatus === "loading" ||
          inviteUserByEmailStatus === "loading"
        }
        createdTeamId={createdTeamId}
        usersInfoById={usersInfoById}
        inviteUserByEmail={async (emailAddresses) => {
          if (!createdTeamId) return;

          dispatch(resetInvalidInvitedStrings());
          const resp = (await dispatch(
            inviteUserByEmail({
              emailAddresses,
              teamId: createdTeamId,
              sendInvitations: sendEmails,
            })
          )) as any;
          if (resp.error) return;
          setShowSuccessScreen(true);
        }}
        userAccountId={currentUserAccountId}
        inviteLink={
          createdTeamId ? teamLinksById[createdTeamId] : companyInviteLink
        }
        allowedDomains={getAllowedDomains(companySettings)}
        departments={!isDepartmentsHidden ? departments : {}}
        onCreateDepartment={() => {
          dispatch(closeCreateTeamModal());
          dispatch(
            openEditDepartmentModal({
              openTeamCreationAfterDepartmentCreation: true,
            })
          );
        }}
        isAdmin={!!isAdmin}
        invalidInvitedStrings={invalidInvitedStrings}
        defaultTeamLeaderId={
          isModalComingFromAdminConsole ? null : currentUserAccountId
        }
        loggedInUserId={currentUserAccountId}
        canUserInviteNewMembers={canUserInviteNewMembers}
        checkedEmails={checkedEmails}
        onCheckEmail={(email) => {
          dispatch(checkNewEmail(email));
        }}
        resetInvalidInvitedStrings={() =>
          dispatch(resetInvalidInvitedStrings())
        }
        setSendEmailSettingProps={{
          setter: setSendEmails,
          value: sendEmails,
        }}
      />
    );
  };

  return (
    <Modal show={showing} onHide={hideModal} size="lg" className="simple-modal">
      {getModalContent()}
    </Modal>
  );
}
