import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

interface Props {
  // Required
  description: string;
  inputs: string[];
  setInputs: (inputs: string[]) => void;
  // Optional
  perItemCharacterLimit?: number;
  placeHolder?: string;
  prefilled?: string[];
  totalItemLimit?: number;
}

export default function MultiInputWithTrashCan({
  prefilled,
  perItemCharacterLimit,
  description,
  totalItemLimit,
  inputs,
  placeHolder = "",
  setInputs,
}: Props) {
  const onInputDelete = (idx: number) => {
    setInputs([...inputs.slice(0, idx), ...inputs.slice(idx + 1)]);
  };

  const onAddInput = () => {
    setInputs([...inputs, ""]);
  };

  const getLastPlaceHolder = () => {
    if (!prefilled) {
      return placeHolder;
    }
    // @ts-ignore: Ignoring because Typescript currently does not support syntax for findLast
    return prefilled.findLast(() => true);
  };

  const onInputChange = (inputValue: string, idx: number) => {
    setInputs(
      inputs.map((input, idy) => {
        // If its not the input we are changing, return the original value
        if (idx !== idy) {
          return input;
        }

        // We splice the input value that it does not exceed the character limit
        if (perItemCharacterLimit) {
          return inputValue.slice(0, perItemCharacterLimit);
        }

        return inputValue;
      })
    );
  };

  const inputFields = inputs.map((value, idx) => {
    return (
      <Form.Group key={idx} className="row-gap-12px">
        <Form.Control
          placeholder={prefilled?.[idx] || getLastPlaceHolder()}
          value={value}
          onChange={(e) => onInputChange(e.target.value, idx)}
        />
        {perItemCharacterLimit ? (
          <Form.Text
            className="text-muted d-flex align-items-center"
            style={{ minWidth: "108px", marginTop: 0 }}
          >
            {value.length}/{perItemCharacterLimit} Characters
          </Form.Text>
        ) : null}
        <Button variant="outline-secondary" onClick={() => onInputDelete(idx)}>
          <FontAwesomeIcon icon={faTrashCan} />
        </Button>
      </Form.Group>
    );
  });

  return (
    <>
      <Form.Group className="column-gap-16px">
        <Form.Label
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          <strong>{description}</strong>
        </Form.Label>
        {inputFields}
      </Form.Group>
      {totalItemLimit === undefined || inputs.length < totalItemLimit ? (
        <Form.Group>
          <Button variant="outline-primary" onClick={() => onAddInput()}>
            + Add Another Field
          </Button>
        </Form.Group>
      ) : null}
    </>
  );
}
