import { memo, useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import {
  acceptInvitation,
  declineInvitation,
  selectDismissedNotificationIds,
  selectAllTeamInvitations,
  selectAllPendingAssessmentsNotifications,
  selectAcceptInvitationStatus,
  selectAllAssessmentResultsNotifications,
  selectAllTeamCreationNotifications,
  selectAllAddTeamMemberNotifications,
  selectAllSendTeamScanReminderNotifications,
  selectAllLaunchTeamScanNotifications,
  dismissNotification,
  selectAllNewDirectReportNotifications,
} from "./slice";
import { getTeamName } from "./helpers";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";
import { setIsNotificationDropdownOpen } from "../GlobalNavbar/slice";
import {
  openCreateTeamModal,
  setIsNewDirectReportModalOpen,
} from "../Modals/slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { sendReminder } from "app/containers/Dashboard/slice";
import { SendTeamScanReminderNotification } from "./types";

interface Props {
  onInviteUsersToTeam: (teamId: number) => void;
}

// This is for the navbar notification dropdown
const NotificationCard = ({ onInviteUsersToTeam }: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const allAssessmentResultsNotifications = useAppSelector(
    selectAllAssessmentResultsNotifications
  );
  const allTeamCreationNotifications = useAppSelector(
    selectAllTeamCreationNotifications
  );
  const dismissedNotificationIds = useAppSelector(
    selectDismissedNotificationIds
  );
  const allAddTeamMemberNotifications = useAppSelector(
    selectAllAddTeamMemberNotifications
  );
  const allSendTeamScanReminderNotifications = useAppSelector(
    selectAllSendTeamScanReminderNotifications
  );
  const allLaunchTeamScanNotifications = useAppSelector(
    selectAllLaunchTeamScanNotifications
  );
  const allNewDirectReportNotifications = useAppSelector(
    selectAllNewDirectReportNotifications
  );
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const acceptInvitationStatus = useAppSelector(selectAcceptInvitationStatus);
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const canUserInviteUsers = useAppSelector(selectUserIsAbleToInviteUsers);

  const [showRemindWarningModal, setShowRemindWarningModal] =
    useState<null | SendTeamScanReminderNotification>(null);

  const onActionItemClick = useCallback(() => {
    dispatch(setIsNotificationDropdownOpen(false));
  }, [dispatch]);

  const pendingTeamNotificationsElms = useMemo(() => {
    const onAcceptInvitationClick = async (
      teamId: number,
      notificationId: number
    ) => {
      await dispatch(acceptInvitation({ teamId, notificationId }));
      navigate(`/TeamGuide/${teamId}`);
      onActionItemClick();
    };

    const pendingNotifications: React.JSX.Element[] = [];
    allPendingTeamNotifications.forEach((notification) => {
      const teamObj = teamsById[notification.teamId];
      if (!teamObj) {
        return;
      }
      const isDismissed = dismissedNotificationIds.includes(
        notification.notificationId
      );
      if (isDismissed) {
        return;
      }
      const { teamId, notificationId } = notification;
      pendingNotifications.push(
        <div key={notification.notificationId} className="notification">
          <p>
            You've been invited to join <b>{teamObj.teamName}</b>
          </p>
          <div className="notification-buttons">
            <div className="row-gap-8px">
              <Button
                disabled={acceptInvitationStatus === "loading"}
                onClick={() => onAcceptInvitationClick(teamId, notificationId)}
                variant="secondary-blue"
              >
                Join team
              </Button>
              <Button
                variant="secondary-gray"
                onClick={() =>
                  dispatch(declineInvitation({ teamId, notificationId }))
                }
                className="border-0"
              >
                Decline
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return pendingNotifications;
  }, [
    allPendingTeamNotifications,
    teamsById,
    dispatch,
    dismissedNotificationIds,
    navigate,
    acceptInvitationStatus,
    onActionItemClick,
  ]);

  const pendingAssessmentNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allPendingAssessmentNotifications.forEach((notification) => {
      const teamInfo = notification?.teamId && teamsById[notification.teamId];
      const isDismissed = dismissedNotificationIds.includes(
        notification.notificationId
      );

      if (isDismissed) {
        return;
      }

      if (
        !teamInfo ||
        isDismissed ||
        // This might be a temporary way to handle this, but if the team is private and the user is not a member of the team, then we don't want to show the notification
        (teamInfo.private === 1 && Object.keys(teamInfo).length === 1)
      ) {
        return;
      }

      pendingNotifications.push(
        <div key={notification.notificationId} className="notification">
          <p>
            You have been invited by {notification.invitedByFirstName} to take
            the TEAMscan{" "}
            {teamInfo ? (
              <>
                for <b>{getTeamName(teamInfo.teamId, teamsById)}</b>
              </>
            ) : null}
            .
          </p>
          <div className="notification-buttons">
            <div className="row-gap-8px">
              <Button
                onClick={() => {
                  navigate(
                    `/survey/team360?eventId=${notification.eventId}&teamId=${notification.teamId}`
                  );
                }}
                variant="secondary-blue"
              >
                {notification.droppedOffAt ? "Resume" : "Begin"} Survey
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return pendingNotifications;
  }, [
    allPendingAssessmentNotifications,
    teamsById,
    dismissedNotificationIds,
    navigate,
  ]);

  const assessmentResultsNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allAssessmentResultsNotifications.forEach(({ notificationId, teamId }) => {
      const teamInfo = teamId && teamsById[teamId];

      if (!teamInfo) {
        return;
      }

      pendingNotifications.push(
        <div key={notificationId} className="notification">
          <p>
            You have unread TEAMscan results for{" "}
            <b>{getTeamName(teamId, teamsById)}</b>.
          </p>
          <div className="notification-buttons">
            <div className="row-gap-8px">
              <Button
                onClick={() => {
                  navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
                  dispatch(dismissNotification(notificationId));
                  onActionItemClick();
                }}
                variant="secondary-blue"
              >
                View Results
              </Button>
              <Button
                variant="secondary-gray"
                onClick={() => dispatch(dismissNotification(notificationId))}
                className="border-0"
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return pendingNotifications;
  }, [
    allAssessmentResultsNotifications,
    teamsById,
    navigate,
    dispatch,
    onActionItemClick,
  ]);

  const teamCreationNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    if (!canUserCreateTeams) {
      return pendingNotifications;
    }

    allTeamCreationNotifications.forEach(({ notificationId }) => {
      pendingNotifications.push(
        <div key={notificationId} className="notification">
          <p>Are you a team leader? Create your first team now.</p>
          <div className="notification-buttons">
            <div className="row-gap-8px">
              <Button
                onClick={() => {
                  dispatch(openCreateTeamModal());
                  onActionItemClick();
                }}
                variant="secondary-blue"
              >
                Create a team
              </Button>
              <Button
                variant="secondary-gray"
                onClick={() => dispatch(dismissNotification(notificationId))}
                className="border-0"
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return pendingNotifications;
  }, [
    allTeamCreationNotifications,
    dispatch,
    canUserCreateTeams,
    onActionItemClick,
  ]);

  const addTeamMemberNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    let filteredMemberInvitations = [...allAddTeamMemberNotifications];
    // if the user cannot invite new users we should hide any notifications that don't have a teamId
    if (!canUserInviteUsers) {
      filteredMemberInvitations = filteredMemberInvitations.filter(
        (notification) => !!notification.teamId
      );
    }
    filteredMemberInvitations.forEach(({ notificationId, teamId }) => {
      pendingNotifications.push(
        <div key={notificationId} className="notification">
          <p>
            Invite members to{" "}
            <b>{teamId ? getTeamName(teamId, teamsById) : "Organization"}</b>
          </p>
          <div className="notification-buttons">
            <div className="row-gap-8px">
              <Button
                onClick={() => {
                  if (teamId) onInviteUsersToTeam(teamId);
                }}
                variant="secondary-blue"
              >
                Invite a team member
              </Button>
              <Button
                variant="secondary-gray"
                onClick={() => dispatch(dismissNotification(notificationId))}
                className="border-0"
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return pendingNotifications;
  }, [
    allAddTeamMemberNotifications,
    dispatch,
    canUserInviteUsers,
    teamsById,
    onInviteUsersToTeam,
  ]);

  const sendTeamScanReminderNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];

    allSendTeamScanReminderNotifications.forEach(
      ({ notificationId, teamId }) => {
        pendingNotifications.push(
          <div key={notificationId} className="notification">
            <p>
              Remind team members to take the TEAMscan for{" "}
              <b>{getTeamName(teamId, teamsById)}</b>.
            </p>
            <div className="notification-buttons">
              <div className="row-gap-8px">
                <Button
                  onClick={() => {
                    setShowRemindWarningModal({ notificationId, teamId });
                  }}
                  variant="secondary-blue"
                >
                  Remind
                </Button>
                <Button
                  variant="secondary-gray"
                  onClick={() => dispatch(dismissNotification(notificationId))}
                  className="border-0"
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        );
      }
    );
    return pendingNotifications;
  }, [allSendTeamScanReminderNotifications, dispatch, teamsById]);

  const launchTeamScanNotificationsElms = useMemo(() => {
    return allLaunchTeamScanNotifications.map(({ notificationId, teamId }) => {
      const text = (
        <p>
          Launch TEAMscan survey{" "}
          {teamId ? (
            <>
              for <b>{getTeamName(teamId, teamsById)}</b>
            </>
          ) : null}{" "}
          to start building teamwork on this team.
        </p>
      );
      return (
        <div key={notificationId} className="notification">
          {text}
          <div className="notification-buttons">
            <div className="row-gap-8px">
              <Button
                onClick={() => {
                  if (teamId) {
                    dispatch(showScheduleAssessmentModalForTeamId(teamId));
                  } else {
                    dispatch(showScheduleAssessmentModal());
                  }
                }}
                variant="secondary-blue"
              >
                Launch survey
              </Button>
              <Button
                variant="secondary-gray"
                onClick={() => dispatch(dismissNotification(notificationId))}
                className="border-0"
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      );
    });
  }, [allLaunchTeamScanNotifications, dispatch, teamsById]);

  const newDirectReportNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allNewDirectReportNotifications.forEach(
      ({ notificationId, subjectUserAccountId }) => {
        const userInfo =
          subjectUserAccountId && usersById[subjectUserAccountId];
        if (!userInfo) {
          return;
        }
        const firstName = userInfo.firstName ?? "";
        const lastName = userInfo.lastName ?? "";

        pendingNotifications.push(
          <div key={notificationId} className="notification">
            <p>
              You have a new direct report, {firstName} {lastName}.
            </p>
            <div className="notification-buttons">
              <div className="row-gap-8px">
                <Button
                  onClick={() => {
                    dispatch(
                      setIsNewDirectReportModalOpen({
                        userAccountId: Number(subjectUserAccountId),
                      })
                    );
                    dispatch(dismissNotification(notificationId));
                    onActionItemClick();
                  }}
                  variant="secondary-blue"
                >
                  View Details
                </Button>
                <Button
                  variant="secondary-gray"
                  onClick={() => dispatch(dismissNotification(notificationId))}
                  className="border-0"
                >
                  Dismiss
                </Button>
              </div>
            </div>
          </div>
        );
      }
    );
    return pendingNotifications;
  }, [allNewDirectReportNotifications, dispatch, onActionItemClick, usersById]);

  const displayNotification = () => {
    const pendingNotifications = [
      ...teamCreationNotificationsElms,
      ...addTeamMemberNotificationsElms,
      ...launchTeamScanNotificationsElms,
      ...sendTeamScanReminderNotificationsElms,
      ...pendingTeamNotificationsElms,
      ...assessmentResultsNotificationsElms,
      ...pendingAssessmentNotificationsElms,
      ...newDirectReportNotificationsElms,
    ];
    if (pendingNotifications?.length) {
      return <div>{pendingNotifications}</div>;
    }

    return (
      <div
        className="empty-card"
        style={{
          margin: "16px",
          padding: "20px",
        }}
      >
        <span>No notifications</span>
        <p>
          Invites to join teams or to take the TEAMscan survey will be shown
          here
        </p>
      </div>
    );
  };

  return (
    <>
      <WarningModal
        modalTitle="Remind Team Members"
        warningTitle="Remind all team members who have not completed the TEAMscan?"
        warningMessage="Send out an email reminder to an team members who have not completed the TEAMscan survey for this instance."
        isOpen={!!showRemindWarningModal}
        onConfirmClick={async () => {
          if (!showRemindWarningModal) return;
          await dispatch(
            sendReminder({
              reminderType: "remindUserToTakeAssessment",
              teamId: showRemindWarningModal.teamId,
            })
          );
          dispatch(dismissNotification(showRemindWarningModal.notificationId));
          setShowRemindWarningModal(null);
        }}
        hideModal={() => {
          setShowRemindWarningModal(null);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      <div className="notification-box">
        <div
          style={{
            padding: "16px",
            paddingBottom: "0px",
          }}
        >
          <h3>Notifications</h3>
        </div>
        {displayNotification()}
      </div>
    </>
  );
};

export default memo(NotificationCard);
