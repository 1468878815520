import CoachBotPrompts from "app/containers/CoachBotPrompts";
import Login from "app/containers/Login";
import Loading from "app/storybookComponents/Loading";
import OrganizationGuide from "app/containers/CompanyGuide";
import { Route, Routes } from "react-router-dom";
import Dashboard from "app/containers/Dashboard";
import Search from "app/containers/Search";
import UserSettings from "app/containers/UserSettings";
import TeamGuide from "app/containers/TeamGuide";
import UserGuide from "app/containers/UserGuide";
import DepartmentGuide from "app/containers/DepartmentGuide";
import SkillsGuide from "app/containers/SkillsGuide";
import HiddenAdminConsole from "app/containers/HiddenAdminConsole";
import AdminConsole from "app/containers/AdminConsole";
import AdvancedAnalytics from "app/containers/AdvancedAnalytics";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectConfigCatFlag,
  selectCurrentUserAccountId,
  selectGetConfigCatFlagsStatus,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import {
  selectGetTeamAndDepartmentLeadsStatus,
  selectIsDepartmentsHidden,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import AccountSetup from "app/containers/AccountSetup";
import ResetPasswordFlow from "app/containers/Login/ResetPasswordFlow";
import Assessment from "app/containers/Assessment";
import DepartmentInsightReport from "app/containers/DepartmentInsightReport";
import Departments from "app/containers/Departments";
import NavigateBackButton from "app/components/NavigateBackButton";
import AdvancedCsvUpload from "app/containers/AdvancedCsvUpload";

export default function AllRoutes() {
  const user = useAppSelector(selectCurrentUserAccountId);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const getTeamAndDepartmentLeadsStatus = useAppSelector(
    selectGetTeamAndDepartmentLeadsStatus
  );
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const coachBoConversationHistoryFeatureFlag = useAppSelector(
    selectConfigCatFlag("develop_coachboconversationhistory")
  );
  const getConfigCatFlagsStatus = useAppSelector(selectGetConfigCatFlagsStatus);

  const getCoachbotPromptRoute = () => {
    return <Route path="/CoachBoPrompts" element={<CoachBotPrompts />} />;
  };

  const getDepartmentPaths = () => {
    if (isDepartmentsHidden) {
      return null;
    }

    // TODO: Remove this check once the feature flag is removed.
    if (getConfigCatFlagsStatus === "loading") {
      return (
        <>
          <Route
            path="/DepartmentInsightReport/:departmentId"
            element={<Loading />}
          />
          <Route
            path="/Department/:departmentId/Surveys"
            element={<Loading />}
          />
          <Route path="/DepartmentGuide/:departmentId" element={<Loading />} />
        </>
      );
    }

    if (!coachBoConversationHistoryFeatureFlag) {
      return (
        <>
          <Route
            path="/DepartmentInsightReport/:departmentId"
            element={
              <div className="page admin-console">
                <div className="header-buttons">
                  <NavigateBackButton />
                </div>
                <DepartmentInsightReport />
              </div>
            }
          />
          <Route
            path="/Department/:departmentId/Surveys"
            element={<Departments />}
          />
        </>
      );
    }

    return (
      <>
        <Route path="/Search/Departments" element={<Search />} />
        <Route
          path="/DepartmentGuide/:departmentId"
          element={<DepartmentGuide />}
        />
      </>
    );
  };

  const getAdminPaths = () => {
    if (!isAdmin) {
      return null;
    }

    return (
      <>
        <Route path="/HiddenAdminConsole" element={<HiddenAdminConsole />} />
        <Route path="/AdminConsole" element={<AdminConsole />} />
        <Route path="/AdminConsole/:tabSelected" element={<AdminConsole />} />
        <Route path="/AdvancedAnalytics" element={<AdvancedAnalytics />} />
      </>
    );
  };

  const getTeamLeaderPaths = () => {
    // If not admin and if we have not fetched the team and department leads then we need to show the loading screen for the team leader paths.
    if (!isAdmin && getTeamAndDepartmentLeadsStatus === "loading") {
      const teamLeaderPaths = ["/TeamGuide/:teamId/TeamSettings"];
      return teamLeaderPaths.map((path) => (
        <Route key={path} path={path} element={<Loading />} />
      ));
    }

    // If not admin and if the user is not a team leader then we need to return null. so that the team leader paths are not shown.
    if (!isAdmin && !teamAndDepartmentLeaderOf?.teams?.length) {
      return null;
    }

    // If the user is a team leader then we need to show the team leader paths.
    return (
      <Route path="/TeamGuide/:teamId/TeamSettings" element={<TeamGuide />} />
    );
  };

  const getUserPaths = () => {
    // If the user is not logged in then we need to show the login page.
    if (!user) {
      return <Route path="*" element={<Login />} />;
    }

    return (
      <>
        {getAdminPaths()}
        {getDepartmentPaths()}
        {getTeamLeaderPaths()}
        <Route path="/" element={<Dashboard />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/Search/People" element={<Search />} />
        <Route path="/Search/Teams" element={<Search />} />
        <Route path="/UserSettings" element={<UserSettings />} />
        <Route path="/OrganizationGuide" element={<OrganizationGuide />} />
        <Route path="/TeamGuide/:teamId" element={<TeamGuide />} />
        <Route path="/TeamGuide/:teamId/TeamMembers" element={<TeamGuide />} />
        <Route
          path="/TeamGuide/:teamId/TeamPersonality"
          element={<TeamGuide />}
        />
        <Route path="/UserGuide" element={<UserGuide />} />

        <Route path="/UserGuide/:userAccountId" element={<UserGuide />} />
        <Route path="/SkillsGuide" element={<SkillsGuide />} />
        <Route path="/AdvancedCsvUpload" element={<AdvancedCsvUpload />} />
        {getCoachbotPromptRoute()}
        {/* // PLOP_ROUTE */}
        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
              routes for. */}
        <Route path="*" element={<div>404: Page Not Found</div>} />
      </>
    );
  };

  return (
    <Routes>
      <Route path="/accountSetup" element={<AccountSetup />} />
      <Route path="/PasswordReset" element={<ResetPasswordFlow />} />
      <Route path="join/:joinType/:inviteCode" element={<AccountSetup />} />
      <Route path="/survey/team360" element={<Assessment />} />
      {getUserPaths()}
    </Routes>
  );
}
