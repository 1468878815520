import { Team, UserInfo } from "app/containers/Global/types";
import { CompanySettings, Department, DepartmentType } from "./types";
import { AssessmentInstance, AssessmentSeries } from "../Assessment/types";
import HelpActionPopover from "app/components/Popovers/HelpActionPopover";

export const getOrganizationDepartments = (departments: {
  [key: string]: Department;
}) => {
  return Object.fromEntries(
    Object.entries(departments).filter(
      ([departmentId, department]) => department.companyAccountId !== 0
    )
  );
};

export const getDepartmentSearchInput = (
  searchPattern: RegExp,
  departments: { [key: string]: Department },
  departmentTypes: { [key: string]: DepartmentType },
  usersInfoById: { [key: string | number]: UserInfo }
): number[] => {
  const filteredDepartments = Object.values(departments).filter(
    ({ name, departmentTypeId, leader }) => {
      // If the department name matches the search input
      if (searchPattern.test(name?.toLowerCase() || "")) {
        return true;
      }

      // If the department type matches the search input
      if (
        departmentTypeId &&
        searchPattern.test(
          departmentTypes[departmentTypeId]?.name?.toLowerCase() || ""
        )
      ) {
        return true;
      }

      const userAccountId =
        typeof leader === "number" ? leader : leader?.userAccountId;

      // If the department leader matches the search input
      if (
        userAccountId &&
        searchPattern.test(
          `${usersInfoById[
            userAccountId
          ]?.firstName.toLowerCase()} ${usersInfoById[
            userAccountId
          ]?.lastName.toLowerCase()}`
        )
      ) {
        return true;
      }

      // Catch all
      return false;
    }
  );

  return filteredDepartments.map(({ departmentId }) => departmentId);
};

const getLeaderNamesString = (
  team: Team,
  usersInfoById: { [key: string | number]: UserInfo }
): string => {
  let leaderNames = "";
  team.teamLeadUserAccountIds?.forEach((userAccountId) => {
    const leader = usersInfoById[userAccountId];
    if (leader) {
      leaderNames += `${leader.firstName?.trim()} ${leader.lastName?.trim()}, `;
    }
  });
  return leaderNames.toLowerCase().trim();
};

export const getTeamSearchInput = (
  searchPattern: RegExp,
  teamInfoById: {
    [teamId: number]: Team;
  },
  departments: { [key: string]: Department },
  usersInfoById: { [key: string | number]: UserInfo }
): number[] => {
  const filteredTeams = Object.values(teamInfoById).filter((team) => {
    const { teamName, departmentId } = team;
    // If the team name matches the search input
    if (searchPattern.test(teamName.toLowerCase() || "")) {
      return true;
    }
    // If the department name matches the search input
    if (
      departmentId &&
      searchPattern.test(departments[departmentId]?.name?.toLowerCase() || "")
    ) {
      return true;
    }

    // If the team leader matches the search input
    const leaderNameString = getLeaderNamesString(team, usersInfoById);
    if (searchPattern.test(leaderNameString.toLowerCase() || "")) {
      return true;
    }

    // Catch all
    return false;
  });
  return filteredTeams.map(({ teamId }) => teamId);
};

export const getPeopleSearchInput = (
  searchPattern: RegExp,
  usersInfoById: { [key: string | number]: UserInfo }
): number[] => {
  const filteredUsers = Object.values(usersInfoById).filter(
    ({ firstName, lastName, emailAddress }) => {
      // If the user's first name matches the search input
      if (searchPattern.test(firstName.toLowerCase() || "")) {
        return true;
      }
      // If the user's last name matches the search input
      if (searchPattern.test(lastName.toLowerCase() || "")) {
        return true;
      }
      // If the user's email matches the search input
      if (searchPattern.test(emailAddress.toLowerCase() || "")) {
        return true;
      }

      // Catch all
      return false;
    }
  );

  return filteredUsers.map(({ userAccountId }) => userAccountId);
};

export const getAllowedDomains = (
  companySettings?: CompanySettings | null
): "ALL" | string[] => {
  // If email setting is 1 that means that the admin console setting is set to allow all domains, so return undefined
  if (companySettings?.emailSetting === 1 || !companySettings) {
    return "ALL";
  }
  // If email setting is 2 that means that we only allow the original domain, so return that
  if (companySettings.emailSetting === 2) {
    return [companySettings.originalDomain];
  }

  if (companySettings.emailSetting === 3) {
    return companySettings.customDomainList ?? [];
  }
  return [];
};

export const getDepartmentLeadId = (
  departmentId: number | undefined,
  departmentsById: {
    [departmentId: number]: Department;
  } | null
): null | number => {
  if (!departmentsById || !departmentId) {
    return null;
  }
  const department = departmentsById[departmentId];
  if (!department) {
    return null;
  }
  const leaderId =
    typeof department.leader === "number"
      ? department.leader
      : department.leader?.userAccountId;
  return leaderId ?? null;
};

export const getSurveyTableInviteeToolTip = ({
  assessment,
  onSeeSettings,
  onRemind,
  hideActionButtons,
}: {
  assessment: AssessmentSeries | AssessmentInstance;
  onSeeSettings?: () => void;
  onRemind?: () => void;
  hideActionButtons?: boolean;
}) => {
  const { status } = assessment;
  const getStatusColor = () => {
    switch (status) {
      case "Active":
        return "green";
      case "Upcoming":
        return "yellow";
      case "Past":
      default:
        return "grey";
    }
  };

  const getPrimaryActionButton = () => {
    if (hideActionButtons) {
      return undefined;
    }

    if (status === "Active" && onRemind) {
      // when its active we need to check the the teamIds is equal to 1, if it is then return the remind button otherwise return undefined
      if (assessment.teamIds.length !== 1) {
        return undefined;
      }

      return {
        text: "Remind members",
        onClick: onRemind,
      };
    }

    if (status === "Upcoming" && onSeeSettings) {
      return {
        text: "Configure Settings",
        onClick: onSeeSettings,
      };
    }
  };

  const getSecondaryActionButton = () => {
    if (hideActionButtons) {
      return undefined;
    }
    if (status === "Active" && onSeeSettings) {
      return {
        text: "Expand survey period",
        onClick: onSeeSettings,
      };
    }
  };

  const textElms: JSX.Element[] = [
    <strong key="title">TEAMscan</strong>,
    <div className={`label-tag bigger ${getStatusColor()} m-0`} key="label">
      {status}
    </div>,
    <p key="info1">{assessment?.info}</p>,
  ];

  if (status === "Active" || status === "Past") {
    textElms.push(
      <p key="info2">
        Out of {assessment?.totalInvited ?? 0} invited members,{" "}
        {assessment?.totalCompleted ?? 0} completed responses were collected.
      </p>
    );
  }

  return (
    <HelpActionPopover
      textElms={textElms}
      primaryButton={getPrimaryActionButton()}
      secondaryButton={getSecondaryActionButton()}
    />
  );
};

export const isAssessmentInstance = (
  assessment: AssessmentSeries | AssessmentInstance
): assessment is AssessmentInstance => {
  return !!(assessment as AssessmentInstance)?.assessmentInstanceId;
};
