import { memo } from "react";

interface Props {
  isOn: boolean;
  handleToggle: () => void;
  size?: "sm" | "md" | "lg";
}

const Toggle = ({ isOn, handleToggle, size = "md" }: Props) => {
  return (
    <div
      className={`toggle ${size} ${isOn ? "active" : ""}`}
      onClick={handleToggle}
    >
      <div className="toggle-thumb" />
    </div>
  );
};

export default memo(Toggle);
