import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "app/storybookComponents/Button";
import TEAM360Icon from "resources/icons/TEAM-assessment-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectCurrentUserInfo,
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForDepartmentId,
  showScheduleAssessmentModalForOrganization,
} from "app/components/LaunchAssessmentModal/slice";
import {
  selectAssessmentInformation,
  selectDepartments,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import {
  selectDepartmentsMostRecentAssessmentsInstancesEntities,
  selectTeamsMostRecentAssessmentsInstancesEntities,
} from "app/containers/Assessment/slice";
import SurveyResultsModal from "app/components/Modals/SurveyResultsModal";
import { setShowModal } from "app/components/Onboarding/slice";
import { useState } from "react";
import { getS } from "utils/helperFunctions";

export default function ShortcutsCard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const allDepartmentsMostRecentAssessments = useAppSelector(
    selectDepartmentsMostRecentAssessmentsInstancesEntities
  );
  const departments = useAppSelector(selectDepartments);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const userInfo = useAppSelector(selectCurrentUserInfo);

  const [showResultsModal, setShowResultsModal] = useState(false);

  const getIcon = (icon?: IconProp, iconSource?: string) => {
    if (!icon && !iconSource) {
      return <div />;
    }

    if (icon) {
      return (
        <div className="small-square-icon sapphire-10">
          <FontAwesomeIcon icon={icon} className="icon" />
        </div>
      );
    }

    return <img src={iconSource} alt="icon" />;
  };

  const getNestedCard = ({
    title,
    description,
    icon,
    iconSource,
    onClick,
  }: {
    title: string;
    description: string;
    icon?: IconProp;
    iconSource?: string;
    onClick?: () => void;
    key: string | number;
  }) => (
    <Card onClick={onClick} className="nested-card">
      <div>{getIcon(icon, iconSource)}</div>
      <div className="column-gap-4px">
        <p className="sapphire-150-text fw-bold">{title}</p>
        <p className="grey-text small-body-text">{description}</p>
      </div>
      <div className="ms-auto">
        <FontAwesomeIcon icon="chevron-right" className="icon" />
      </div>
    </Card>
  );

  // we are going to iterate over all of the user's teams and show how many of them have an active survey
  const getTotalCountWithTeamScanResults = (
    entity: "teams" | "departments",
    ids?: number[]
  ): number => {
    let count = 0;
    ids?.forEach((id) => {
      const mostRecentAssessmentInfo = assessmentInformation?.[entity]?.[id];
      if (mostRecentAssessmentInfo?.activeAssessment) {
        count++;
      }
    });
    return count;
  };

  const getTeamIdsFromDepartments = (departmentIds: number[]): number[] => {
    const teamIdSet = new Set<number>();
    departmentIds.forEach((departmentId) => {
      const department = departments[departmentId];
      department?.teams?.forEach((teamId) => {
        teamIdSet.add(teamId);
      });
    });
    return Array.from(teamIdSet);
  };

  const getDescription = (
    totalWithResults: number,
    totalTeams: number,
    isAdmin: boolean,
    isDepartment: boolean
  ) => {
    if (totalWithResults === 0) {
      if (isAdmin) {
        return "None of the teams in your organization have an active TEAMscan survey right now";
      }
      if (isDepartment) {
        return "None of the teams in your departments have an active TEAMscan survey right now.";
      }
      return "None of your teams have an active TEAMscan survey right now";
    }

    let entity = "";
    if (isAdmin) {
      entity = " in your organization";
    } else if (isDepartment) {
      entity = " in your departments";
    }

    return `You have ${totalWithResults} out of ${totalTeams} team${getS(
      totalTeams
    )}${entity} with an active TEAMscan survey`;
  };

  const getLaunchSurveyCard = () => {
    const teams = teamAndDepartmentLeaderOf?.teams ?? [];
    const departments = teamAndDepartmentLeaderOf?.departments ?? [];
    const usersTeams = userInfo?.teamIds ?? [];

    const teamsLeaderOfLength = teams.length;
    const departmentsLeaderOfLength = departments.length;
    const isDepartmentLeader = departmentsLeaderOfLength > 0;

    const isAdminOrLeader =
      isAdmin || teamsLeaderOfLength > 0 || departmentsLeaderOfLength > 0;

    if (!isAdminOrLeader) return null;

    let teamsToConsider: number[] = [];
    // if the user is an admin then we should show all the teams
    if (isAdmin) {
      teamsToConsider = Object.keys(teamsById).map((id) => Number(id));
    } else if (isDepartmentLeader) {
      // if the user is a department leader then we should only show the teams that are in the departments that they lead and any teams the departments are a part of
      teamsToConsider = getTeamIdsFromDepartments(departments);
      // if the user is a team leader then we should only show the teams that they lead
    } else {
      teamsToConsider = usersTeams;
    }

    const totalTeamsWithResults = getTotalCountWithTeamScanResults(
      "teams",
      teamsToConsider
    );

    if (totalTeamsWithResults < teamsToConsider.length) {
      const description = getDescription(
        totalTeamsWithResults,
        teamsToConsider.length,
        !!isAdmin,
        departmentsLeaderOfLength > 0
      );

      return getNestedCard({
        title: "Launch the TEAMscan survey",
        description,
        iconSource: TEAM360Icon,
        onClick: () => dispatch(showScheduleAssessmentModal()),
        key: "launch-survey",
      });
    }

    return null;
  };

  const getSeeResultsCard = () => {
    const usersTeams = isAdmin
      ? Object.keys(teamsById).map((id) => Number(id))
      : teamAndDepartmentLeaderOf?.teams;
    const manageCardObj = {
      title: "Manage or view TEAMscan results",
      icon: ["fal", "poll-people"] as IconProp,
      onClick: () => {
        setShowResultsModal(true);
      },
    };

    const departmentsUserIsLeaderOf = teamAndDepartmentLeaderOf?.departments;
    if (departmentsUserIsLeaderOf?.length && !isAdmin) {
      let totalDepartmentsWithResults = 0;
      departmentsUserIsLeaderOf.forEach((departmentId) => {
        const mostRecentAssessment =
          allDepartmentsMostRecentAssessments[departmentId];
        if (mostRecentAssessment?.hasEnoughResults) {
          totalDepartmentsWithResults++;
        }
      });

      if (totalDepartmentsWithResults === departmentsUserIsLeaderOf.length) {
        return getNestedCard({
          ...manageCardObj,
          description: "View all of your department results",
          key: "view-department-results",
        });
      }

      return getNestedCard({
        ...manageCardObj,
        description: `${totalDepartmentsWithResults} of ${departmentsUserIsLeaderOf.length} departments have TEAMscan results available`,
        key: "view-department-results",
      });
    }

    if (usersTeams?.length) {
      // we will first save the total teams that already have enough results to show.
      let totalTeamsWithResults = 0;
      usersTeams.forEach((teamId) => {
        const mostRecentAssessment = teamsMostRecentAssessments[teamId];
        if (mostRecentAssessment?.hasEnoughResults) {
          totalTeamsWithResults++;
        }
      });

      if (totalTeamsWithResults === usersTeams.length) {
        return getNestedCard({
          ...manageCardObj,
          description: "View all of your team results",
          key: "view-team-results",
        });
      }
      const countString = `${totalTeamsWithResults} of ${usersTeams.length}`;

      return getNestedCard({
        ...manageCardObj,
        description: isAdmin
          ? `${countString} teams in your organization has TEAMscan results available`
          : `${countString} teams have TEAMscan results available`,
        key: "view-team-results",
      });
    }

    return null;
  };

  const getPersonalityShortcutCard = () =>
    getNestedCard({
      title: "See my personality",
      description:
        "View your workplace superpower, hidden strength, personality, and more",
      icon: ["fal", "map"],
      onClick: () => {
        navigate("/UserGuide?tab=Workplace+Insights");
      },
      key: "personality",
    });

  const getMyTeamsShortcutCard = () =>
    getNestedCard({
      title: "See my teams",
      description:
        "View teams you belong to or lead, their TEAMscan results and team personality",
      icon: ["fal", "users"],
      onClick: () => {
        navigate("/Search/Teams");
      },
      key: "my-teams",
    });

  const getSkillLibraryShortcutCard = () =>
    getNestedCard({
      title: "See skill library",
      description:
        "View teamwork skills and dimensions used in the TEAMscan survey",
      icon: ["fal", "book-open"],
      onClick: () => {
        navigate("/SkillsGuide");
      },
      key: "skill-library",
    });

  const getMyProfileShortcutCard = () =>
    getNestedCard({
      title: "See my profile",
      description:
        "View and edit your public profile, contact information, and user settings",
      icon: ["fal", "user"],
      onClick: () => {
        navigate("/UserGuide");
      },
      key: "my-profile",
    });

  const getAdminConsoleShortcutCard = () =>
    getNestedCard({
      title: "Go to admin console",
      description: "Manage people teams, departments, surveys, and more",
      icon: ["fal", "gear"],
      onClick: () => {
        navigate("/AdminConsole/Dashboard");
      },
      key: "admin-console",
    });

  const getNestedCards = () => {
    const cards: JSX.Element[] = [];

    const launchSurveyCard = getLaunchSurveyCard();
    if (launchSurveyCard) {
      cards.push(launchSurveyCard);
    }

    const seeResultsCard = getSeeResultsCard();
    if (seeResultsCard) {
      cards.push(seeResultsCard);
    }
    const skillLibraryCard = getSkillLibraryShortcutCard();
    const myTeamsCard = getMyTeamsShortcutCard();
    const personalityCard = getPersonalityShortcutCard();
    const myProfileCard = getMyProfileShortcutCard();

    // Go admin console, Launch, Manage, See profile
    if (isAdmin) {
      cards.unshift(getAdminConsoleShortcutCard());
      cards.push(myProfileCard, skillLibraryCard, myTeamsCard, personalityCard);
    } else if (
      teamAndDepartmentLeaderOf?.departments?.length ||
      teamAndDepartmentLeaderOf?.teams?.length
    ) {
      cards.push(myTeamsCard, myProfileCard, personalityCard, skillLibraryCard);
    } else {
      cards.push(myProfileCard, personalityCard, myTeamsCard, skillLibraryCard);
    }

    return cards.splice(0, 4);
  };

  const getSurveyResultModalEmptyCard = (selectedDepartmentId?: number) => {
    const onShowGeneralTeam360Information = () => {
      dispatch(
        setShowModal({
          eventType: "generalTeam360Information",
        })
      );
      setShowResultsModal(false);
    };
    if (selectedDepartmentId) {
      return (
        <div className="empty-card">
          <span>No TEAMscan Results In This Department</span>
          <p>
            No teams have an active TEAMscan currently. Launch a TEAMscan for
            your department or one or more teams now.
          </p>
          <div className="action-buttons">
            <Button
              onClick={() => {
                dispatch(
                  showScheduleAssessmentModalForDepartmentId(
                    selectedDepartmentId
                  )
                );
                setShowResultsModal(false);
              }}
            >
              Launch TEAMscan
            </Button>
            <Button
              variant="secondary-blue"
              onClick={onShowGeneralTeam360Information}
            >
              See details
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="empty-card">
        <span>No TEAMscan Results In This Organization</span>
        <p>
          No teams have an active TEAMscan currently. Launch a TEAMscan for your
          organization, department, or one or more teams now.
        </p>
        <div className="action-buttons">
          <Button
            onClick={() => {
              dispatch(showScheduleAssessmentModalForOrganization());
              setShowResultsModal(false);
            }}
          >
            Launch TEAMscan
          </Button>
          <Button
            variant="secondary-blue"
            onClick={onShowGeneralTeam360Information}
          >
            See details
          </Button>
        </div>
      </div>
    );
  };

  const getTeamIds = (): number[] => {
    // if the user is an admin then we should show all the teams
    if (isAdmin) {
      return Object.keys(teamsById).map((id) => Number(id));
    }

    const teamIdsSet: Set<number> = new Set();
    // if the user is a team leader then we should only show the teams that they lead
    teamAndDepartmentLeaderOf?.teams?.forEach((teamId) => {
      teamIdsSet.add(teamId);
    });

    // if the user is a department leader then we only show the teams that are in the departments that they lead and any teams the departments are a part of
    teamAndDepartmentLeaderOf?.departments?.forEach((departmentId) => {
      const department = departments[departmentId];
      department?.teams?.forEach((teamId) => {
        teamIdsSet.add(teamId);
      });
    });

    return Array.from(teamIdsSet);
  };

  return (
    <>
      <SurveyResultsModal
        onHide={() => setShowResultsModal(false)}
        show={showResultsModal}
        teamIds={getTeamIds()}
        showManageSurveysButton={!!isAdmin}
        isAdmin={!!isAdmin}
        departmentIds={
          isAdmin ? undefined : teamAndDepartmentLeaderOf?.departments
        }
        getEmptyCard={getSurveyResultModalEmptyCard}
      />
      <Card className="dashboard-shortcut-card">
        <h2>Shortcuts</h2>
        <div className="nested-cards-container">{getNestedCards()}</div>
      </Card>
    </>
  );
}
