import { useEffect, useRef } from "react";
import { trackEvent } from "utils/trackingFunctions";

const useTrackActiveTimeInComponent = () => {
  const activeTimeRef = useRef(0);
  const startTime = useRef(Date.now());

  // Event handler for visibility change
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      startTime.current = Date.now();
    } else {
      activeTimeRef.current += Date.now() - startTime.current;
      startTime.current = Date.now();
    }
  };

  const beforeUnloadHandler = () => {
    activeTimeRef.current += Date.now() - startTime.current;
    const timeSpentInSeconds = activeTimeRef.current / 1000;
    trackEvent("Time Spent", {
      timeSpentInSeconds,
    });
  };

  // Set up event listener for visibility change
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", beforeUnloadHandler);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  return null;
};

export default useTrackActiveTimeInComponent;
