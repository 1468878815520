export const getTeamName = (
  teamId: number,
  teamsById: {
    [key: number]: { teamName: string };
  }
) => {
  if (!teamsById[teamId]) return "";
  const { teamName } = teamsById[teamId];
  const teamNameLower = teamName.toLowerCase();
  // if the name does not contain the word "team", add it to the end
  if (!teamNameLower.includes("team")) return `${teamName} Team`;
  return teamName;
};
